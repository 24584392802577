import "@rainbow-me/rainbowkit/styles.css";
import { ethers } from "ethers";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  walletConnectWallet,
  tokenPocketWallet,
  bitgetWallet,
  trustWallet,
} from "@rainbow-me/rainbowkit/wallets";
import React from "react";
import { Environment } from "src/helpers/environment/Environment/Environment";
import { configureChains, createConfig, type WalletClient, useWalletClient, sepolia, mainnet } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { createPublicClient, http, PublicClient } from "viem";
const ChainList = Number(process.env.REACT_APP_CHAIN_ID) == 56 ? bsc : bscTestnet;

export const { chains, publicClient, webSocketPublicClient } = configureChains([ChainList], [
  // publicProvider()
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_RPC_URL || 'https://bsc-dataseed.binance.org',
      }),
    }),
]);

const needsInjectedWalletFallback =
  typeof window !== "undefined" && window.ethereum && !window.ethereum.isMetaMask && !window.ethereum.isCoinbaseWallet;

const walletConnectProjectId = Environment.getWalletConnectProjectId() as string;

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      bitgetWallet({ projectId: walletConnectProjectId, chains }),
      tokenPocketWallet({ projectId: walletConnectProjectId, chains }),
      metaMaskWallet({ projectId: walletConnectProjectId, chains, shimDisconnect: true }),
      trustWallet({ projectId: walletConnectProjectId, chains }),
      braveWallet({ chains, shimDisconnect: true }),
      rainbowWallet({ projectId: walletConnectProjectId, chains }),
      okxWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({ projectId: walletConnectProjectId, chains }),
      coinbaseWallet({ appName: "", chains }),
      rabbyWallet({ chains, shimDisconnect: true }),
      safeWallet({ chains }),
      ...(needsInjectedWalletFallback ? [injectedWallet({ chains, shimDisconnect: true })] : []),
    ],
  },
]);

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new ethers.providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  return React.useMemo(() => (walletClient ? walletClientToSigner(walletClient) : undefined), [walletClient]);
}
export const client: any = createPublicClient({
  chain: ChainList,
  transport: http(process.env.REACT_APP_RPC_URL),
  batch: {
    multicall: true, // 启用 multicall
  },
});

export const wagmiClient = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});
