import { ethers, BigNumberish, BigNumber } from "ethers";
import { addresses, OHM_INITIAL_PRICE } from "../constants";
import OlympusStakingv2ABIJson from "../abi/OlympusStakingv2.json";
import FuseProxyABIJson from "../abi/FuseProxy.json";
import DistributorContractABIJson from "../abi/Distributor.json";
import dayjs, { duration } from "dayjs";
import utc from "dayjs/plugin/utc";
import ierc20AbiJson from "../abi/IERC20.json";

import sOHMv2Json from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice, trim } from "../helpers";
import apollo from "../lib/apolloClient";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { OlympusStakingv2, SOhmv2, FuseProxy } from "../typechain";
import ReleasePoolABIJson from "../abi/ReleasePool.json";

import { erc20ABI } from "wagmi";
const OlympusStakingv2ABI = OlympusStakingv2ABIJson.abi;
const FuseProxyABI = FuseProxyABIJson.abi;
const DistributorContractABI = DistributorContractABIJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const ReleasePoolABI = ReleasePoolABIJson.abi;
const ierc20Abi = ierc20AbiJson.abi;

dayjs.extend(utc);

interface IProtocolMetrics {
  readonly timestamp: string;
  readonly ohmCirculatingSupply: string;
  readonly sOhmCirculatingSupply: string;
  readonly totalSupply: string;
  readonly ohmPrice: string;
  readonly marketCap: string;
  readonly totalValueLocked: string;
  readonly treasuryMarketValue: string;
  readonly nextEpochRebase: string;
  readonly nextDistributedOhm: string;
  readonly totalBurn: string;
}

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: any, { dispatch }) => {
    const protocolMetricsQuery = `
  query {
    _meta {
      block {
        number
      }
    }
    protocolMetrics(first: 1, orderBy: timestamp, orderDirection: desc) {
      timestamp
      ohmCirculatingSupply
      sOhmCirculatingSupply
      totalSupply
      ohmPrice
      marketCap
      totalValueLocked
      treasuryMarketValue
      nextEpochRebase
      nextDistributedOhm
      totalBurn
    }
  }
`;
    const graphData = await apollo<{ protocolMetrics: IProtocolMetrics[] }>(protocolMetricsQuery);
    console.log("graphData", graphData);

    if (!graphData || graphData == null) {
      console.error("Returned a null response when querying TheGraph");
      return;
    }
    const oneDayAgoTimestamp = dayjs().utc().subtract(3, "hour").unix();
    // const oneDayAgoTimestamp = dayjs().utc().subtract(1, "day").unix();
    console.log("oneDayAgoTimestamp", oneDayAgoTimestamp);
    const protocolPriceQuery = `
       query {
          protocolMetrics(where: {id: "${oneDayAgoTimestamp}"}) {
            id
            ohmPrice
          }
        }
      `;
    const priceData = await apollo(protocolPriceQuery);
    if (!priceData || priceData == null) {
      console.error("Returned a null response when querying TheGraph");
      return;
    }
    console.log("oneDayAgoTimestamp", { priceData });
    const stakingTVL =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.totalValueLocked)
        : 0;
    // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
    // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].ohmPrice);
    let marketPrice;
    try {
      const originalPromiseResult = await dispatch(loadMarketPrice({ networkID, provider })).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error("Returned a null response from dispatch(loadMarketPrice)");
      return;
    }

    const marketCap =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.marketCap)
        : 0;
    const circSupply = graphData.data.protocolMetrics[0]
      ? parseFloat(graphData.data.protocolMetrics[0]?.ohmCirculatingSupply)
      : 0;
    const totalSupply =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.totalSupply)
        : 0;
    const treasuryMarketValue =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.treasuryMarketValue)
        : 0;

    const totalBurn =
      graphData.data.protocolMetrics.length > 0 && graphData.data.protocolMetrics[0]
        ? parseFloat(graphData.data.protocolMetrics[0]?.totalBurn)
        : 0;
    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        stakingTVL,
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
        treasuryMarketValue,
        totalBurn,
      } as any;
    }
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingv2ABI,
      provider,
    ) as OlympusStakingv2;
    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS as string,
      FuseProxyABI,
      provider,
    ) as FuseProxy;
    const stakingDistributorContract = new ethers.Contract(
      addresses[networkID].DISTRIBUTOR_ADDRESS as string,
      DistributorContractABI,
      provider,
    ) as any;
    const ReleasePoolContract = new ethers.Contract(
      addresses[networkID].contributionReleasePool as string,
      ReleasePoolABI,
      provider,
    ) as any;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider) as any;
    const sohmMainContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      sOHMv2,
      provider,
    ) as SOhmv2;
    const [
      currentBlock,
      epoch,
      tokenBalInStaking,
      currentIndex,
      circ,
      totalReserves,
      rate,
      ohmSymbol,
      sohmSymbol,
      LevelDetail150,
      LevelDetail100,
      LevelDetail60,
      LevelDetail30,
      LevelDetail15,
    ] = await Promise.all([
      provider.getBlockNumber(),
      stakingContract.epoch(),
      stakingContract.contractBalance(),
      stakingContract.index(),
      sohmMainContract.circulatingSupply(),
      treasuryContract.totalReserves(),
      stakingDistributorContract.info(0).catch((error: any) => {
        console.log("loadAppDetails rate error", error);
        return [];
      }),
      ohmContract.symbol(),
      sohmMainContract.symbol(),
      ReleasePoolContract.levelDetails(2),
      ReleasePoolContract.levelDetails(3),
      ReleasePoolContract.levelDetails(4),
      ReleasePoolContract.levelDetails(5),
      ReleasePoolContract.levelDetails(6),
    ]);

    const StakeRate = rate[0];
    const endBlock = epoch.endBlock;
    const stakingReward = epoch.distribute;
    const stakingRebase = Number(stakingReward.toString()) / Number(circ.toString());
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;
    const marketValue = marketPrice * totalSupply;
    const _marketPrice = BigNumber.from(String(Number(Math.floor(marketPrice) * 1e9)));
    let times = Number(OHM_INITIAL_PRICE.div(_marketPrice));
    let priceDown = true;
    if (_marketPrice.gt(OHM_INITIAL_PRICE)) {
      priceDown = false;
      times = Number(_marketPrice.div(OHM_INITIAL_PRICE));
    }
    const stakeMinAmount = priceDown
      ? BigNumber.from(100000000000).mul(times)
      : BigNumber.from(100000000000).div(times);

    return {
      endBlock,
      currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
      tokenBalInStaking: ethers.utils.formatUnits(tokenBalInStaking, "9"),
      // ohmBalInDaoAddress: ethers.utils.formatUnits(ohmBalInDaoAddress, "9"),
      totalReserves: ethers.utils.formatUnits(totalReserves, "9"),
      currentBlock,
      fiveDayRate,
      stakingAPY,
      stakingTVL,
      stakingRebase,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      treasuryMarketValue,
      marketValue,
      StakeRate,
      stakeMinAmount: ethers.utils.formatUnits(stakeMinAmount, 18),
      totalBurn,
      level: {
        duration15: Number(LevelDetail15.duration),
        duration30: Number(LevelDetail30.duration),
        duration60: Number(LevelDetail60.duration),
        duration100: Number(LevelDetail100.duration),
        duration150: Number(LevelDetail150.duration),
      },
      ohmSymbol,
      sohmSymbol,
    } as unknown as IAppData;
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }: any) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the OHM price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    console.log("marketPrice", marketPrice);
    marketPrice = marketPrice / Math.pow(10, 9);
  } catch (e) {
    console.error("Error fetching market price", e);
    marketPrice = 0;
  }
  // console.log("marketPrice", marketPrice);

  return { marketPrice };
});

interface IAppData {
  readonly circSupply?: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly loading: boolean;
  readonly loadingMarketPrice: boolean;
  readonly marketCap?: number;
  readonly marketPrice?: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly stakingTVL?: number;
  readonly totalSupply?: number;
  readonly treasuryBalance?: number;
  readonly treasuryMarketValue?: number;
  readonly marketValue?: number;
  readonly endBlock1?: number;
  readonly tokenBalInStaking?: number;
  readonly StakeRate?: number;
  readonly totalReserves?: number;
  readonly stakeMinAmount?: string;
  readonly totalBurn?: string;
  readonly ohmSymbol?: string;
  readonly sohmSymbol?: string;
  readonly level?: {
    duration15: number;
    duration30: number;
    duration60: number;
    duration100: number;
    duration150: number;
  };
}

const initialState: IAppData = {
  loading: false,
  loadingMarketPrice: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
