// 2. 创建自定义 hook 处理 Bond 相关数据
import { useMemo } from "react";
import { useBonds } from "src/hooks";
import { trim } from "src/helpers";

export const useBondData = (chainID: number) => {
  const { bonds } = useBonds(chainID);

  return useMemo(() => {
    const lpBond: any = bonds.filter((bond: any) => bond.bond === "nvb-usdt_lp");
    const usdtBond: any = bonds.filter((bond: any) => bond.bond === "usdt");

    return {
      lpBondPurchased: lpBond && lpBond.length > 0 ? Number(trim(lpBond[0]["purchased"], 2)) : 0,
      usdtBondPurchased: usdtBond && usdtBond.length > 0 ? Number(trim(usdtBond[0]["purchased"], 2)) : 0,
    };
  }, [bonds]);
};
