import { t, Trans } from "@lingui/macro";
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Skeleton,
  CircularProgress,
  Button
} from "@mui/material";
import { CustormPagination } from "src/components";

import React, { useState, useEffect } from "react";
import NoData from "src/assets/images/noData.svg";
import {
  communityJoin,
  getCommunityData,
  getInviteDataTemp,
} from "src/slices/CommunitySlice";
import { useDispatch } from "react-redux";
import { useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { success, info } from "src/slices/MessagesSlice";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";

import { customNumberFormat, shorten } from "src/helpers";
import { INVITE_PER_PAGE } from "src/constants";
import { error } from "../../slices/MessagesSlice";
import InviteTitle from "../../assets/images/invite.png";
import FooterBg from "../../assets/images/footer-bg.png";
import { useInviteData } from "./hooks/useInviteData";
import { ethers } from "ethers";

const Invite = ({ handleScrollTop }: any) => {
  const { isSmallScreen } = useMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const { provider, address, connected, chainID, connect } = useWeb3Context();

  // 使用自定义 hook 获取数据
  const {
    isInvited,
    directReferralQuantity,
    teamNumber,
    referralsList,
    referrer,
    gonsBal,
    sohmBalance,
    teamStaking,
    loadInviteData
  } = useInviteData(provider, chainID, address);

  const [inviteAddr, setInviteAddr] = useState("");
  const pendingTransactions = useAppSelector(state => state.pendingTransactions);
  const isAppLoading = useAppSelector(state => state.app.loading);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loadingSign, setLoadingSign] = useState<boolean>(false);
  // 处理邀请地址
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    const defaultReferrer = (referrer || "0x0000000000000000000000000000000000000000") !== "0x0000000000000000000000000000000000000000" ? referrer : "";
    const inviteCode = code || "";
    setInviteAddr((defaultReferrer !== '' ? defaultReferrer : inviteCode) as string);
  }, [location.search, referrer, address]);

  // 加载数据
  useEffect(() => {
    if (connected && address) {
      loadInviteData(1);
      verifyAddr();
      // console.log('verifyAddr address', address)
    }
  }, [connected, chainID, address]);

  // 处理加入
  const handleJoin = async () => {
    if (inviteAddr?.toLowerCase() === address?.toLowerCase()) {
      return dispatch(error(t`You can't invite yourself!`));
    }
    await dispatch(communityJoin({ provider, referer: inviteAddr, networkID: chainID }));
    await loadInviteData();
  };

  // 处理页面变化
  const handlePageChange = async (event: any, page: number) => {
    await loadInviteData(page);
  };

  const handleCopyLink = () => {
    dispatch(success(t`Copy successful!`));
  };

  const pageCount = Math.ceil((directReferralQuantity || 0) / INVITE_PER_PAGE);


  const verifyAddr = () => {
    const verifiedAddresses = JSON.parse(localStorage.getItem("verifiedAddresses") || "{}");
    // console.log('verifyAddr address', { verifiedAddresses })
    // 如果已验证,验证签名是否匹配
    if (verifiedAddresses[address]) {
      const storedSignature = verifiedAddresses[address];
      const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
      const recoveredAddress = ethers.utils.verifyMessage(message, storedSignature);

      if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
        setIsVerified(true);
      }
    } else {
      setIsVerified(false);

    }
    process.env.REACT_APP_NO_SIGN == '1' && setIsVerified(true);

  };

  const verifyWallet = async () => {
    try {
      if (!provider || !address) return false;

      // 检查 localStorage 是否已验证
      const verifiedAddresses = JSON.parse(localStorage.getItem("verifiedAddresses") || "{}");

      // 如果已验证,验证签名是否匹配
      if (verifiedAddresses[address]) {
        const storedSignature = verifiedAddresses[address];
        const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
        const recoveredAddress = ethers.utils.verifyMessage(message, storedSignature);

        if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
          setIsVerified(true);
          return true;
        }
      }

      const signer = provider;
      const message = `Welcome to NovaBank,\nSignature Verification Identity View Data.`;
      setLoadingSign(true);
      const signature = await signer.signMessage(message);

      // 验证签名
      const recoveredAddress = ethers.utils.verifyMessage(message, signature);
      const isValid = recoveredAddress.toLowerCase() === address.toLowerCase();

      if (isValid) {
        // 存储签名而不是布尔值
        verifiedAddresses[address] = signature;
        localStorage.setItem("verifiedAddresses", JSON.stringify(verifiedAddresses));
        setIsVerified(true);
        setLoadingSign(false);

        return true;
      }

      if (process.env.REACT_APP_NO_SIGN == '1') {
        return true;

      } else {
        return false
      }
    } catch (error) {
      setLoadingSign(false);

      console.error("Verification failed:", error);
      return false;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          pt: { sm: "88px", xs: "20px" },
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          position: "relative",
          zIndex: "1",
        }}
      >
        <Box
          sx={{
            width: { xs: "150px", sm: "197px" },
            height: { xs: "37.2px", sm: "61.2px" },
            mb: { xs: "20px", sm: "60px" },
            px: { xs: "15px", sm: "0" },
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={InviteTitle} />
        </Box>
        {address ? isVerified ? (<>
          <Box
            sx={{
              position: "relative",
              zIndex: "1",
              width: "100%",
              px: { xs: "15px", sm: "0" },
              mb: {
                xs: "30px",
                sm: "50px",
              },
            }}
          >
            <Grid
              container
              columnSpacing={{
                xs: 1,
                sm: 2,
              }}
            >
              <Grid item xs={12} sm={3}>
                <Box
                  sx={{
                    width: "100%",
                  }}
                  display="flex"
                  flexDirection={{ xs: "row", sm: "column" }}
                >
                  <Box
                    sx={{
                      width: { xs: "50%", sm: "100%" },
                      mr: { xs: 1, sm: 0 },
                      height: { xs: "72px", sm: "134px" },
                      border: "1px solid #2d2d2d",
                      borderRadius: { xs: "6px", sm: "10px" },
                      background: "#151515",
                      mb: { xs: "8px", sm: "16px" },
                      pl: { xs: "15px", sm: "0" },
                    }}
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontWeight: "400",
                        fontSize: { xs: "12px", sm: "18px" },
                        mb: { xs: "6px", sm: "10px" },
                      }}
                    >
                      <Trans>Number of Team</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontWeight: "600",
                      }}
                    >
                      {connected ? (
                        typeof teamNumber == "number" ? (
                          teamNumber
                        ) : (
                          <Skeleton width="100px" />
                        )
                      ) : (
                        0
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: { xs: "50%", sm: "100%" },
                      // width: { xs: "50%", sm: "100%" },
                      height: { xs: "72px", sm: "134px" },
                      border: "1px solid #2d2d2d",
                      borderRadius: { xs: "6px", sm: "10px" },
                      background: "#151515",
                      mb: { xs: "8px", sm: "16px" },
                      pl: { xs: "15px", sm: "0" },
                    }}
                    display="flex"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontWeight: "400",
                        fontSize: { xs: "12px", sm: "18px" },
                        mb: { xs: "6px", sm: "10px" },
                      }}
                    >
                      <Trans>Direct invitation</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontWeight: "600",
                      }}
                    >
                      {connected ? (
                        typeof directReferralQuantity == "number" ? (
                          directReferralQuantity
                        ) : (
                          <Skeleton width="100px" />
                        )
                      ) : (
                        0
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box
                  sx={{
                    width: "100%",
                    height: { xs: "auto", sm: "286px" },
                    p: { xs: "15px", sm: "40px" },
                    borderRadius: { xs: "6px", sm: "10px" },
                    background: "#151515",
                    border: "1px solid #2d2d2d",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  justifyContent="center"
                >
                  <Box sx={{ mb: { xs: "30px", sm: 0.75 }, width: "100%" }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "14px", sm: "18px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="left"
                    >
                      <Trans>Referrer</Trans>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      mb: { xs: "10px", sm: "36px" },
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={{
                      xs: "column",
                      sm: "row",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "auto" },
                        flex: { xs: "auto", sm: "1" },
                        borderRadius: "6px",
                        border: "1px solid #2d2d2d",
                        background: "#0c0c0c",
                        height: "44px",
                        mr: { xs: "0", sm: "16px" },
                        mb: { xs: "10px", sm: "0" },
                        px: "14px",
                      }}
                      display="flex"
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontSize: isSmallScreen ? "14px" : "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "noWrap",
                        }}
                      >
                        {inviteAddr ? shorten(inviteAddr) : ""}
                      </Typography>
                    </Box>
                    {/* <Box
                    sx={{
                      width: "160px",
                      borderRadius: "6px",
                      height: "42px",
                      border: "1px solid #000",
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                    }}
                  > */}
                    <LoadingButton
                      // variant="contained"
                      onClick={handleJoin}
                      disabled={
                        isInvited ||
                        isPendingTxn(pendingTransactions, "community_join") ||
                        !connected ||
                        !inviteAddr
                      }
                      loading={isPendingTxn(
                        pendingTransactions,
                        "community_join"
                      )}
                      loadingPosition={"end"}
                      endIcon={
                        // isPendingTxn(pendingTransactions, "approve_tha_exchange") ? (
                        //   <CircularProgress color="inherit" size={16} />
                        // ) : null
                        isPendingTxn(pendingTransactions, "community_join") && (
                          <CircularProgress color="inherit" size={16} />
                        )
                      }
                      sx={{
                        width: { xs: "100%", sm: "160px" },
                        borderRadius: "6px",
                        height: "42px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        // width: "100%",
                        // height: "100%",
                        // px: 3.5,
                        whiteSpace: "nowrap",
                        opacity: isInvited ? 0.5 : 1,
                        "&:hover": {
                          transform: "scale(1.02)",
                          background: "#FCD000",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                      >
                        {isInvited
                          ? t`Joined`
                          : txnButtonText(
                            pendingTransactions,
                            "community_join",
                            t`Join`
                          )}
                        {/* <Trans>Join</Trans> */}
                      </Typography>
                    </LoadingButton>
                    {/* </Box> */}
                  </Box>

                  <Box sx={{ mb: 0.75, width: "100%" }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "14px", sm: "18px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>Referral</Trans>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%", mb: { xs: "10px" } }}
                    flexDirection={{
                      xs: "column",
                      sm: "row",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "calc(100% - 176px)" },
                        flex: { xs: "auto", sm: "1" },
                        borderRadius: "6px",
                        border: "1px solid #2d2d2d",
                        background: "#0c0c0c",
                        mr: { xs: "0", sm: "16px" },
                        mb: { xs: "10px", sm: "0" },
                        height: "44px",
                        px: "14px",
                      }}
                      display="flex"
                      alignItems={"center"}
                    >
                      <Typography
                        sx={{
                          color: "#C7C8CC",
                          fontSize: isSmallScreen ? "14px" : "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          whiteSpace: "nowrap",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${window.location.origin}/invite?code=${address ?? ""}`}
                      </Typography>
                    </Box>
                    <CopyToClipboard
                      text={`${window.location.origin}/invite?code=${address ?? ""
                        }`}
                    >
                      <Box
                        onClick={handleCopyLink}
                        // variant="contained"
                        sx={{
                          cursor: "pointer",
                          width: { xs: "100%", sm: "160px" },
                          borderRadius: "6px",
                          height: "42px",
                          border: "1px solid #000",
                          background: "#FCD000",
                          boxShadow: "-3px 3px 0px 0px #ECAA00",
                          whiteSpace: "nowrap",
                          "&:hover": {
                            transform: "scale(1.02)",
                          },
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          <Trans>Copy</Trans>
                        </Typography>
                      </Box>
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* invitation list */}
          <Box
            sx={{
              width: "100%",
              px: {
                xs: "15px",
                sm: "0",
              },
            }}
          >
            <Box sx={{ mb: 1.25 }}
              display="flex"
              alignItems={{ sm: "center", xs: "flex-start" }}
              flexDirection={{ xs: "column", sm: "row" }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "18px", sm: "24px" },
                  fontWeight: "600",
                  mr: { xs: 2.5, sm: 4 },
                  mb: { xs: 2, sm: 0 },
                }}
              >
                <Trans>Invitation List</Trans>
              </Typography>
              <Box display="flex" alignItems={"center"} justifyContent={"center"}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "20px" },
                    fontWeight: "400",
                    whiteSpace: "nowrap",
                    mr: 1.25,
                  }}
                >
                  <Trans>Team Total Staked Principal</Trans>:
                </Typography>
                {address ? (
                  typeof teamStaking == "string" ? (
                    <Typography
                      sx={{
                        color: "#EAECEF",
                        fontSize: { xs: "14px", sm: "20px" },
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      {customNumberFormat(Number(teamStaking), 4)} NVB
                    </Typography>
                  ) : (
                    <Box display="flex" alignItems="center" justifyContent={"center"} sx={{ width: "100%" }}>
                      <Skeleton width="150px" />
                    </Box>
                  )
                ) : (
                  <Typography
                    sx={{
                      color: "#EAECEF",
                      fontSize: { xs: "14px", sm: "20px" },
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    0 NVB
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                px: 1,
                border: "1px solid #2d2d2d",
                background: "#151515",
                borderRadius: { xs: "6px", sm: "10px" },
                mb: {
                  sm: "100px",
                  xs: "40px",
                },
              }}
            >
              <TableContainer sx={{ minHeight: "330px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          borderBottom: "none",
                          width: '33.33%'
                        }}
                      >
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"flex-start"}
                        // onClick={e => handleHover(e)}
                        // sx={{
                        //   cursor: "pointer",
                        // }}
                        >
                          <Typography
                            sx={{
                              color: "#fcd000",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                          >
                            <Trans>Level</Trans>
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "#848e9c",
                          fontSize: "16px",
                          fontWeight: "400",
                          borderBottom: "none",
                          width: '33.33%'
                        }}
                      >
                        <Trans>Address</Trans>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: "#848e9c",
                          fontSize: "16px",
                          fontWeight: "400",
                          borderBottom: "none",
                          width: '33.33%'
                        }}
                      >
                        <Trans>Total principal</Trans>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referralsList && referralsList?.length > 0 ? (
                      referralsList.map((item: any, index: number) => (
                        <TableRow
                          key={item.member}
                          sx={{
                            height: "56px",
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 1.25,
                              width: '33.33%'
                            }}
                            align="left"
                          >
                            <Typography
                              sx={{
                                color: "#eaecef",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              <Trans>Level</Trans> 1
                              {/* <Trans>Level</Trans> {Number(item.level) - Number(accountLevel)} */}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 1.25,
                              width: '33.33%'
                            }}
                            align="center"
                          >
                            <Typography
                              sx={{
                                color: "#eaecef",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {shorten(item.referral)}
                              {/* {shorten(item.member)} */}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              p: 1.25,
                              width: '33.33%'
                            }}
                            align="right"
                          >
                            <Typography
                              sx={{
                                color: "#eaecef",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {customNumberFormat(Number(item.teamStaking), 4)} NVB
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          sx={{
                            height: "300px",
                            borderBottom: "none",
                          }}
                        >
                          <Box
                            sx={{ width: "100%", height: "100%" }}
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                            flexDirection={"column"}
                          >
                            <img src={NoData} alt="No Data" />
                            <Typography
                              sx={{
                                pt: 2,
                                color: "#848E9C",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>No data</Trans>
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {referralsList && referralsList?.length > 0 && (
                <Box
                  sx={{
                    width: "100%",
                    py: "50px",
                  }}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <CustormPagination
                    siblingCount={isSmallScreen ? 0 : 1}
                    boundaryCount={1}
                    count={pageCount}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </>) :
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              sx={{
                borderRadius: { xs: "6px", sm: "10px" },
                border: "1px solid #2d2d2d",
                background: "#151515",
                width: "100%",
                py: { sm: "40px", xs: "25px" },
                mb: {
                  sm: "15px",
                  xs: "8px",
                },
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "160px" },
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <LoadingButton
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                  loadingPosition="end"
                  loading={loadingSign}
                  onClick={verifyWallet}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Verify yourself</Trans>
                  </Typography>
                </LoadingButton>
              </Box>
            </Box>
          </Box>

          : <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
            display="flex"
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              sx={{
                borderRadius: { xs: "6px", sm: "10px" },
                border: "1px solid #2d2d2d",
                background: "#151515",
                width: "100%",
                py: { sm: "40px", xs: "25px" },
                mb: {
                  sm: "15px",
                  xs: "8px",
                },
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "160px" },
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                  onClick={connect}
                  variant="contained"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Connect Wallet</Trans>
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>}
      </Box>
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
      {/* <Footer handleScrollTop={handleScrollTop} /> */}
    </Box>
  );
};

export default Invite;
