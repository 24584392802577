import { useCallback, useMemo } from "react";
import { useAppSelector } from "src/hooks";
import { useDispatch } from "react-redux";
import { approveSwap, clearAmount, getAmountsIn, getAmountsOut, getSelectToken, swapToken } from "src/slices/SwapThunk";
import { getBalances, loadAccountDetails } from "src/slices/AccountSlice";

let debounceTimeout: NodeJS.Timeout;

export const useSwapData = (provider: any, chainID: number, address: string) => {
  const dispatch = useDispatch();

  // 从 Redux 获取状态
  const topBal = useAppSelector(state => state.swap.topBal);
  const bottomBal = useAppSelector(state => state.swap.bottomBal);
  const amountsOut = useAppSelector(state => state.swap.amountsOut);
  const amountsIn = useAppSelector(state => state.swap.amountsIn);
  const isAppLoading = useAppSelector(state => state.app.loading);
  const pendingTransactions = useAppSelector(state => state.pendingTransactions);

  const balances = useAppSelector(state => ({
    ohmBalance: state.account.balances?.ohm,
    usdtBalance: state.account.balances?.dai,
    OHMAllowanceInRouter: state.account.ohmAllowanceInRouter,
    wbnbAllowanceInRouter: state.account.wbnbAllowanceInRouter,
    usdtAllowanceInRouter: state.account.usdtAllowanceInRouter,
  }));

  // 检查代币授权
  const hasAllowance = useCallback(
    (token: string) => {
      if (token === "NVB") return Number(balances.OHMAllowanceInRouter);
      if (token === "USDT") return Number(balances.usdtAllowanceInRouter);
      if (token === "WBNB") return Number(balances.wbnbAllowanceInRouter);
      return 0;
    },
    [balances],
  );

  // 加载代币数据
  const loadTokenData = useCallback(
    async (topToken: string, bottomToken: string) => {
      if (address && provider) {
        await dispatch(
          getSelectToken({
            provider,
            address,
            networkID: chainID,
            topToken,
            bottomToken,
          }),
        );
      }
    },
    [dispatch, provider, chainID, address],
  );

  // 处理代币兑换
  const handleSwap = useCallback(
    async (params: { amountIn: string; amountOut: string; topToken: string; bottomToken: string }) => {
      const { amountIn, amountOut, topToken, bottomToken } = params;
      if (!amountIn || !amountOut) return;

      await dispatch(
        swapToken({
          address,
          provider,
          networkID: chainID,
          amountsIn: amountIn,
          amountsOut: amountOut,
          topToken,
          bottomToken,
        }),
      );

      await loadTokenData(topToken, bottomToken);
    },
    [dispatch, provider, chainID, address],
  );

  // 处理代币授权
  const handleApprove = useCallback(
    async (params: { topToken: string; bottomToken: string }) => {
      const { topToken, bottomToken } = params;
      await dispatch(
        approveSwap({
          address,
          provider,
          networkID: chainID,
          type: topToken === "USDT",
          topToken,
          bottomToken,
        }),
      );

      await Promise.all([
        dispatch(getBalances({ address, provider, networkID: chainID })),
        dispatch(loadAccountDetails({ address, provider, networkID: chainID })),
      ]);
    },
    [dispatch, provider, chainID, address],
  );

  // 处理输入金额变化
  const handleAmountChange = useCallback(
    (params: { value: string; isInput: boolean; topToken: string; bottomToken: string }) => {
      const { value, isInput, topToken, bottomToken } = params;

      if (!value) {
        dispatch(clearAmount());
        clearTimeout(debounceTimeout);
        return;
      }

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(() => {
        if (isInput) {
          dispatch(
            getAmountsOut({
              address,
              provider,
              networkID: chainID,
              amountsIn: value,
              type: topToken !== "NVB",
              topToken,
              bottomToken,
            }),
          );
        } else {
          dispatch(
            getAmountsIn({
              address,
              provider,
              networkID: chainID,
              amountsOut: value,
              topToken,
              bottomToken,
            }),
          );
        }
      }, 500);
    },
    [dispatch, provider, chainID, address],
  );

  return {
    // 状态
    topBal,
    bottomBal,
    amountsOut,
    amountsIn,
    isAppLoading,
    pendingTransactions,
    balances,

    // 方法
    hasAllowance,
    loadTokenData,
    handleSwap,
    handleApprove,
    handleAmountChange,
  };
};
