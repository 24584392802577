import { memo, useMemo } from "react";
import "./treasury-dashboard.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Skeleton } from "@mui/material";
import {
  Paper,
  Grid,
  Box,
  Zoom,
  Container,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import DashboardTitle from "src/assets/images/dashboard-title.png";
import {
  MarketCap,
  OHMPrice,
  TotalMinting,
  SOHMPrice,
  CircSupply,
  BackingPerOHM,
  TotalSupply,
  CurrentIndex,
} from "./components/Metric/Metric";
import { useAppSelector, useBonds, useWeb3Context } from "src/hooks";
import {
  customNumberFormat,
  formatCurrency,
  toolNumber,
  trim,
} from "../../helpers";
import { allBondsMap } from "src/helpers/AllBonds";
import Chart from "src/components/Chart/Chart.jsx";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import { TreasuryChart, MintingChart } from "./components/Chart/Chart";
import {
  TotalValueDepositedGraph,
  MarketValueGraph,
  RiskFreeValueGraph,
  ProtocolOwnedLiquidityGraph,
  OHMStakedGraph,
  APYOverTimeGraph,
  RunwayAvailableGraph,
  OHMBurningGraph,
  BTokenMintingGraph,
} from "./components/Graph/Graph";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";
import { ResponsiveContainer } from "recharts";
import { useTreasuryData } from './hooks/useTreasuryData';
import { useBondData } from './hooks/useBondData';

const TreasuryDashboard = memo(() => {
  const { provider, address, chainID } = useWeb3Context();
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 480px)");

  // 使用自定义 hooks 获取数据
  const {
    marketPrice,
    totalSupply,
    treasuryMarketValue,
    stakedBal,
    circBal,
    stakingAPY,
    trimmedStakingAPY
  } = useTreasuryData();

  const {
    lpBondPurchased: lpBondPurchasedVal,
    usdtBondPurchased: usdtBondPurchasedVal
  } = useBondData(chainID);

  // 加载状态
  const isAppLoading = useAppSelector(state => state.app.loading);

  // 图表数据处理
  const chartData = useMemo(() => ({
    mintingChartData: {
      stakedAmount: stakedBal,
      circulatingSupply: circBal
    },
    treasuryChartData: {
      lpBondValue: lpBondPurchasedVal,
      usdtBondValue: usdtBondPurchasedVal
    }
  }), [stakedBal, circBal, lpBondPurchasedVal, usdtBondPurchasedVal]);

  return (
    <Box
      display="flex"
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        mb: "100px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          width: { xs: "100%", sm: "97%" },
          pt: { sm: "87px", xs: "18px" },
          px: { xs: "15px", sm: "0" },
        }}
      >
        {/* dashboard top */}
        <Box
          sx={{
            mb: {
              xs: "20px",
              sm: "50px",
            },
          }}
          display="flex"
          alignItems={"cneter"}
        >
          <Box
            display="flex"
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Box
              sx={{
                mr: { sm: "20px", xs: "12px" },
              }}
            >
              <StarIcon />
            </Box>
            <Box
              sx={{}}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DashboardIcon />
              {/* <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "35.192px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                <Trans>Dashboard</Trans>
              </Typography> */}
            </Box>
          </Box>
          {/* mobile tile */}
          <Box
            sx={{
              width: "100%",
              "&>img": {
                width: "50%",
              },
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <img src={DashboardTitle} alt="dashboard" />
          </Box>
        </Box>
        <Box>
          {!isSmallScreen ? (
            <Box
              sx={{
                width: "100%",
                mb: "50px",
              }}
            >
              <Grid
                container
                columnSpacing={{
                  xs: 0,
                  sm: 2,
                }}
              >
                <Grid item xs={12} sm={3}>
                  <MarketCap />
                  <TotalMinting />
                  <OHMPrice />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: { xs: "6px", sm: "10px" },
                      border: "1px solid #2d2d2d",
                      background: "#151515",
                      py: { sm: "22px", xs: "15px" },
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        height: "100%",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                          flexDirection={"column"}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              pl: { sm: "22px", xs: "15px" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "18px",
                                fontWeight: "400",
                                // mb: 1,
                              }}
                            >
                              <Trans>Total Minting Amount</Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#eaecef",
                                fontSize: "24px",
                                fontWeight: "600",
                              }}
                            >
                              {totalSupply ? (
                                `${customNumberFormat(totalSupply, 4)} NVB`
                              ) : (
                                <Skeleton width="200px" />
                              )}
                            </Typography>
                          </Box>
                          <ResponsiveContainer width="100%" height="100%">
                            <MintingChart />
                          </ResponsiveContainer>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                          flexDirection={"column"}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              pr: { sm: "22px", xs: "15px" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "18px",
                                fontWeight: "400",
                                // mb: 1,
                              }}
                            >
                              <Trans>Treasury</Trans>
                            </Typography>
                            <Typography
                              sx={{
                                color: "#eaecef",
                                fontSize: "24px",
                                fontWeight: "600",
                              }}
                            >
                              {typeof treasuryMarketValue == "number" ? (
                                `$${customNumberFormat(treasuryMarketValue, 2)}`
                              ) : (
                                <Skeleton width="200px" />
                              )}
                            </Typography>
                          </Box>
                          <ResponsiveContainer width="100%" height="100%">
                            <TreasuryChart />
                          </ResponsiveContainer>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                mb: "30px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <MarketCap />
                </Grid>
                <Grid item xs={12}>
                  <TotalMinting />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <OHMPrice />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid #2D2D2D",
                      background: "#151515",
                      p: "15px",
                      borderRadius: "6px",
                    }}
                  >
                    {/* minting chart */}
                    <Box
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        width: "100%",
                        mb: 2,
                        // height: "100%",
                      }}
                      flexDirection={"column"}
                    >
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#848E9C",
                            fontSize: "16px",
                            fontWeight: "400",
                            // mb: 1,
                          }}
                        >
                          <Trans>Total Minting Amount</Trans>
                        </Typography>
                        {totalSupply ? (
                          <Typography
                            sx={{
                              color: "#EAECEF",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {customNumberFormat(Number(totalSupply), 4)} NVB
                          </Typography>
                        ) : (
                          <Box
                            display="flex"
                            alignItems={"center"}
                          // justifyContent={"flex-end"}
                          >
                            <Skeleton width="200px" />
                          </Box>
                        )}
                        {/* <Typography
                          sx={{
                            color: "#eaecef",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {totalSupply ? (
                            `${customNumberFormat(
                              Number(trim(totalSupply, 4))
                            )} NVB`
                          ) : (
                            <Skeleton width="200px" />
                          )}
                        </Typography> */}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <ResponsiveContainer width="70%" height="100%">
                          <MintingChart />
                        </ResponsiveContainer>
                        <Box
                          sx={{
                            width: "30%",
                            height: "100%",
                          }}
                          display="flex"
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Box
                            sx={{
                              mb: "25px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              align="right"
                              sx={{
                                color: "#FCD000",
                                fontSize: "12px",
                                fontWeight: "400",
                                mb: "5px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Trans>Staked</Trans>
                            </Typography>
                            {stakedBal ? (
                              <Typography
                                align="right"
                                sx={{
                                  color: "#FCD000",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {stakedBal} NVB
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                              >
                                <Skeleton width="80px" />
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <Typography
                              align="right"
                              sx={{
                                color: "#EAECEF",
                                fontSize: "12px",
                                fontWeight: "400",
                                whiteSpace: "noWrap",
                              }}
                            >
                              <Trans>Circulating supply</Trans>
                            </Typography>
                            {circBal ? (
                              <Typography
                                align="right"
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {circBal} NVB
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                              >
                                <Skeleton width="80px" />
                              </Box>
                            )}
                            {/* <Typography
                              align="right"
                              sx={{
                                color: "#EAECEF",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              {circBal ? (
                                `${circBal} NVB`
                              ) : (
                                <Skeleton width="80px" />
                              )}
                            </Typography> */}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* treasury chart */}
                    <Box
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        width: "100%",
                        // height: "100%",
                      }}
                      flexDirection={"column"}
                    >
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#848E9C",
                            fontSize: "16px",
                            fontWeight: "400",
                            mb: 1,
                          }}
                        >
                          <Trans>Treasury</Trans>
                        </Typography>

                        {treasuryMarketValue ? (
                          <Typography
                            sx={{
                              color: "#EAECEF",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                          >
                            {`$${customNumberFormat(treasuryMarketValue, 2)}`}
                          </Typography>
                        ) : (
                          <Box
                            display="flex"
                            alignItems={"center"}
                          // justifyContent={"flex-end"}
                          >
                            <Skeleton width="200px" />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                        }}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <ResponsiveContainer width="70%" height="100%">
                          <TreasuryChart />
                        </ResponsiveContainer>
                        <Box
                          sx={{
                            width: "30%",
                            height: "100%",
                          }}
                          display="flex"
                          flexDirection={"column"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <Box
                            sx={{
                              mb: "25px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              align="right"
                              sx={{
                                color: "#5AF0BE",
                                fontSize: "12px",
                                fontWeight: "400",
                                mb: "5px",
                              }}
                            >
                              <Trans>Bond LP</Trans>
                            </Typography>
                            {lpBondPurchasedVal ? (
                              <Typography
                                align="right"
                                sx={{
                                  color: "#5AF0BE",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                ${lpBondPurchasedVal}
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                              >
                                <Skeleton width="80px" />
                              </Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <Typography
                              align="right"
                              sx={{
                                color: "#62A1FF",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              <Trans>Bond USDT</Trans>
                            </Typography>
                            {typeof usdtBondPurchasedVal == "number" ? (
                              <Typography
                                align="right"
                                sx={{
                                  color: "#62A1FF",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {`$${usdtBondPurchasedVal}`}
                              </Typography>
                            ) : (
                              <Box
                                display="flex"
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                              >
                                <Skeleton width="80px" />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            mb: { sm: "20px", xs: "15px" },
          }}
        >
          <Typography
            sx={{
              color: "#eaecef",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: "600",
            }}
          >
            <Trans>Charts</Trans>
          </Typography>
        </Box>
        <Grid container spacing={2} className="data-grid">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <TotalValueDepositedGraph />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <RiskFreeValueGraph />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <ProtocolOwnedLiquidityGraph />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <OHMStakedGraph />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <RunwayAvailableGraph />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <MarketValueGraph />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <OHMBurningGraph />
            </Box>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                p: isSmallScreen ? 1.875 : 2.5,
                width: "100%",
                height: "100%",
                borderRadius: { sm: "10px", xs: "6px" },
                background: "#151515",
                border: "1px solid #2d2d2d",
              }}
            >
              <BTokenMintingGraph />
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
});

const queryClient = new QueryClient();

// Normally this would be done
// much higher up in our App.
export default () => (
  <QueryClientProvider client={queryClient}>
    <TreasuryDashboard />
  </QueryClientProvider>
);
