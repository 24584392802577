import { useMemo } from "react";
import { useAppSelector } from "src/hooks";
import { useDispatch } from "react-redux";
import { getCommunityData, getInviteData, getContributionData } from "src/slices/CommunitySlice";

export const useInviteData = (provider: any, chainID: number, address: string) => {
  const dispatch = useDispatch();

  // 直接使用 useAppSelector，不要在 useMemo 中嵌套 hooks
  const isInvited = useAppSelector(state => state.community.isInvited);
  const directReferralQuantity = useAppSelector(state => state.community.directReferralQuantity);
  const teamNumber = useAppSelector(state => state.community.teamNumber);
  const referralsList = useAppSelector(state => state.community.referralsList);
  const teamStaking = useAppSelector(state => state.community.teamStaking);
  const referrer = useAppSelector(state => state.community.referrer);
  const gonsBal = useAppSelector(state => state.account.balances?.gonsBal);
  const sohmBalance = useAppSelector(state => state.account.balances?.sOHM);

  const loadInviteData = async (page = 1) => {
    if (address) {
      // await Promise.all([
      dispatch(getCommunityData({ networkID: chainID, address, page }));
      //   dispatch(getInviteData({ page, address })),
      //   // dispatch(getContributionData({ provider, networkID: chainID, address })),
      // ]);
      // await dispatch(getCommunityData({ provider, networkID: chainID, address }));
      // await dispatch(getInviteData({ page, address }));
    }
  };

  return {
    isInvited,
    directReferralQuantity,
    teamNumber,
    referralsList,
    teamStaking,
    referrer,
    gonsBal,
    sohmBalance,
    loadInviteData,
  };
};
