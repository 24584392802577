import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  Skeleton,
} from "@mui/material";
import { useAppSelector } from "src/hooks";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { ReactComponent as InviteIcon } from "src/assets/icons/logo.svg";
import { useMobile } from "src/hooks";
import { useWeb3Context } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { compareDuration, trim } from "src/helpers";
import { useSelector, useDispatch } from "react-redux";
import {
  loadAccountDetails,
} from "src/slices/AccountSlice";
import {
  changeApprovalInterest,
} from "../../slices/StakeThunk";
import {
  getContributionRecordsByPage,
  getTokenContributionRecordsByPage,
  getReleaseRecordsByPage,
  releaseLevelUP,
  approveForDistributor
} from "src/slices/ReleaseSlice";
import { INVITE_PER_PAGE } from "src/constants";
import { error } from "src/slices/MessagesSlice";

const AccelerateModal = ({
  open,
  handleClose,
  record,
  isStakeRecord,
  index,
  isBurnToken,
  changePage
}: any) => {
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  console.log("AccelerateModal record", record);
  const { isSmallScreen } = useMobile();
  const { connected, connect } = useWeb3Context();
  const [days, setDays] = useState<number>(15);
  const level = record.level;
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const levelDuration15 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration15;
  });
  const levelDuration30 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration30;
  });
  const levelDuration60 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration60;
  });
  const levelDuration100 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration100;
  });
  const levelDuration150 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration150;
  });
  const releasePoolAllowance = useAppSelector((state) => {
    return state.account.releasePoolAllowance;
  });
  console.log("app LevelDetail1", {
    levelDuration15,
    levelDuration30,
    levelDuration60,
    levelDuration100,
    levelDuration150,
  });

  const bTokenBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.bTokenBalance;
  });
  const bTokenrewardDistributorAllowance = useAppSelector((state) => {
    return state.account.bTokenrewardDistributorAllowance ?? 0;
  });
  const wbnbBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.wbnb;
  });
  const burnAmt15 = useAppSelector((state) => {
    return state.account.balances && state.account.levelUP.amt15;
  });
  const burnAmt30 = useAppSelector((state) => {
    return state.account.balances && state.account.levelUP.amt30;
  });
  const burnAmt60 = useAppSelector((state) => {
    return state.account.balances && state.account.levelUP.amt60;
  });
  const burnAmt100 = useAppSelector((state) => {
    return state.account.balances && state.account.levelUP.amt100;
  });
  const burnAmt150 = useAppSelector((state) => {
    return state.account.balances && state.account.levelUP.amt150;
  });
  const burnObj: any = {
    "15": burnAmt15,
    "30": burnAmt30,
    "60": burnAmt60,
    "100": burnAmt100,
    "150": burnAmt150,
  };
  const releaseLevelObj: any = {
    "15": 6,
    "30": 5,
    "60": 4,
    "100": 3,
    "150": 2,
    "180": 1,
  };

  useEffect(() => {

    if (open) {
      if (isStakeRecord) {
        setDays(30);
      } else {
        setDays(15);

      }
    }

  }, [open]);

  const handleSelect = (_days: number) => {
    setDays(_days);
  };
  // console.log("level", level);

  const handleAccelerate = async () => {
    console.log("level handleAccelerate", {
      days,
      burnAmt30,
      burnAmt60,
      burnAmt100,
      burnAmt150,
      record,
      index,
    });
    // console.log("release_level_up", {
    //   burningAmt: burnObj[days],
    //   level: releaseLevelObj[days],
    //   releasePoolAllowance,
    //   isStakeRecord,
    //   allowance: Number(releasePoolAllowance) as number >= Number(burnObj[days]),
    //   ballowance: bTokenrewardDistributorAllowance as number >= Number(burnObj[days]),
    //   bool: isStakeRecord && Number(releasePoolAllowance) as number >= Number(burnObj[days]) || bTokenrewardDistributorAllowance as number >= Number(burnObj[days])
    //   // id: Number(record.idx),
    // });
    if (Number(burnObj[days]) > Number(wbnbBalance) && !isBurnToken) {
      return dispatch(error(t`WBNB balance is insufficient to destroy!`));
    }
    // console.log('releaseLevelUP', {
    //   provider,
    //   networkID: chainID,
    //   burningAmt: burnObj[days],
    //   level: releaseLevelObj[days],
    //   // id: Number(record.idx),
    //   id: index,
    //   isStakeRecord,
    //   isBurnToken,
    // })


    await dispatch(
      releaseLevelUP({
        provider,
        networkID: chainID,
        burningAmt: burnObj[days],
        level: releaseLevelObj[days],
        id: index,
        isStakeRecord,
        isBurnToken,
      })
    )


    changePage({}, 1)

    // if (isBurnToken) {
    //   dispatch(
    //     getTokenContributionRecordsByPage({
    //       address,
    //       provider,
    //       networkID: chainID,
    //       page: 1,
    //       limit: INVITE_PER_PAGE,
    //     })
    //   );
    // }
    // if (isStakeRecord) {
    //   dispatch(
    //     getReleaseRecordsByPage({
    //       address,
    //       provider,
    //       networkID: chainID,
    //       page: 1,
    //       limit: INVITE_PER_PAGE,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     getContributionRecordsByPage({
    //       address,
    //       provider,
    //       networkID: chainID,
    //       page: 1,
    //       limit: INVITE_PER_PAGE,
    //     })
    //   );
    // }


    handleClose();
  };
  const isGtTime15 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration15)
  );
  const isGtTime30 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration30)
  );

  const isGtTime60 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration60)
  );
  const isGtTime100 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration100)
  );
  const isGtTime150 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration150)
  );
  console.log("app LevelDetail1", { isGtTime30, record });

  const handleApproveToken = async () => {
    if (isStakeRecord) {
      await dispatch(changeApprovalInterest({
        address,
        provider,
        networkID: chainID,
        token: "WBNB",
      }))
    } else {
      await dispatch(
        approveForDistributor({
          address,
          provider,
          networkID: chainID,
          token: 'bToken',//wbnb
        })
      );
    }

    await dispatch(
      loadAccountDetails({
        address,
        provider,
        networkID: chainID,
      }),
    );

  }

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: isSmallScreen ? 1.875 : 0,
      }}
      onClose={handleClose}
    // hideBackdrop
    >
      <Box
        sx={{
          width: { xs: "345px", sm: "540px" },
          height: { xs: "auto", sm: "auto" },
          minHeight: "400px",
          borderRadius: { xs: "6px", sm: "10px" },
          background: "#151515",
          border: "1px solid #2d2d2d",
          px: { xs: "15px", sm: "30px" },
          py: 2.5,
        }}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{
            mb: { xs: "31px", sm: "40px" },
          }}
        >
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: "600",
            }}
            align="center"
          >
            <Trans>Accelerate</Trans>
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0,
            }}
          >
            <SvgIcon sx={{ color: "#EAECEF" }} component={XIcon} />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              mb: "14px",
            }}
          >
            {isGtTime15 && !isStakeRecord && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  cursor: "pointer",
                  border:
                    days == 15 ? "1px solid #FCD000" : "1px solid #2d2d2d",
                  p: "14px",
                  borderRadius: "6px",
                  mb: 2,
                }}
                onClick={() => handleSelect(15)}
              >
                <Typography
                  sx={{
                    color: days == 15 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>15D</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: days == 15 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>Estimated destruction</Trans>{" "}
                  {trim(Number(burnAmt15), 4)} {isBurnToken ? "NVB" : "WBNB"}
                </Typography>
              </Box>
            )}
            {isGtTime30 && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  cursor: "pointer",
                  border:
                    days == 30 ? "1px solid #FCD000" : "1px solid #2d2d2d",
                  p: "14px",
                  borderRadius: "6px",
                  mb: 2,
                }}
                onClick={() => handleSelect(30)}
              >
                <Typography
                  sx={{
                    color: days == 30 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>30D</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: days == 30 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>Estimated destruction</Trans>{" "}
                  {trim(Number(burnAmt30), 4)} {isBurnToken ? "NVB" : "WBNB"}
                </Typography>
              </Box>
            )}
            {isGtTime60 && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  cursor: "pointer",
                  border:
                    days == 60 ? "1px solid #FCD000" : "1px solid #2d2d2d",
                  p: "14px",
                  borderRadius: "6px",
                  mb: 2,
                }}
                onClick={() => handleSelect(60)}
              >
                <Typography
                  sx={{
                    color: days == 60 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>60D</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: days == 60 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>Estimated destruction</Trans>{" "}
                  {trim(Number(burnAmt60), 4)} {isBurnToken ? "NVB" : "WBNB"}
                </Typography>
              </Box>
            )}
            {isGtTime100 && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  cursor: "pointer",
                  border:
                    days == 100 ? "1px solid #FCD000" : "1px solid #2d2d2d",
                  p: "14px",
                  borderRadius: "6px",
                  mb: 2,
                }}
                onClick={() => handleSelect(100)}
              >
                <Typography
                  sx={{
                    color: days == 100 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>100D</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: days == 100 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>Estimated destruction</Trans>{" "}
                  {trim(Number(burnAmt100), 4)} {isBurnToken ? "NVB" : "WBNB"}
                </Typography>
              </Box>
            )}
            {isGtTime150 && (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  cursor: "pointer",
                  border:
                    days == 150 ? "1px solid #FCD000" : "1px solid #2d2d2d",
                  p: "14px",
                  borderRadius: "6px",
                  mb: 2,
                }}
                onClick={() => handleSelect(150)}
              >
                <Typography
                  sx={{
                    color: days == 150 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>150D</Trans>
                </Typography>
                <Typography
                  sx={{
                    color: days == 150 ? "#fcd000" : "#eaecef",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  <Trans>Estimated destruction</Trans>{" "}
                  {trim(Number(burnAmt150), 4)} {isBurnToken ? "NVB" : "WBNB"}
                </Typography>
              </Box>
            )}
          </Box>
          {!isBurnToken && (
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mb: { xs: "25px", sm: "30px" },
              }}
            >
              <Typography
                sx={{
                  color: "#eaecef",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                }}
              >
                <Trans>Balance</Trans>:{" "}
              </Typography>
              <Typography
                sx={{
                  color: "#eaecef",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                }}
              >
                {trim(Number(wbnbBalance), 4)} WBNB
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
            }}
          >
            {
              isStakeRecord && Number(releasePoolAllowance) as number >= Number(burnObj[days]) || !isStakeRecord && bTokenrewardDistributorAllowance as number >= Number(burnObj[days]) ?
                <LoadingButton
                  variant="contained"
                  onClick={handleAccelerate}
                  // disabled
                  disabled={
                    isPendingTxn(pendingTransactions, "release_level_up") ||
                    !connected
                  }
                  loading={isPendingTxn(pendingTransactions, "release_level_up")}
                  // loading
                  loadingPosition="end"
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    height: "44px",
                    border: "1px solid #000",
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      transform: "scale(1.02)",
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                    },
                    "&.Mui-disabled": {
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {txnButtonText(
                      pendingTransactions,
                      "release_level_up",
                      t`Confirm`
                    )}
                  </Typography>
                </LoadingButton> :
                <LoadingButton
                  sx={{
                    width: "100%",
                    borderRadius: "6px",
                    height: "44px",
                    border: "1px solid #000",
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      transform: "scale(1.02)",
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                    },
                    "&.Mui-disabled": {
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                    },
                  }}
                  onClick={handleApproveToken}
                  variant="contained"
                  disabled={isPendingTxn(
                    pendingTransactions,
                    "approve_claim_interest"
                  )}
                  loading={isPendingTxn(
                    pendingTransactions,
                    "approve_claim_interest"
                  )}
                  loadingPosition="end"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {txnButtonText(
                      pendingTransactions,
                      "approve_claim_interest",
                      t`Approve`)}
                  </Typography>
                </LoadingButton>
            }

          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AccelerateModal;
