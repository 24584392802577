import React, { lazy, Suspense } from "react";
import { Box } from "@mui/material";
import { Loading } from "src/components/Loading";

// 只对非首屏关键内容使用懒加载
import Banner from "./Banner"; // 直接导入首屏关键组件
import Highlights from "./Highlights"; // 直接导入首屏关键组件
const CardList = lazy(() => import("./CardList"));
const PartnerCard = lazy(() => import("./PartnerCard"));
const Map = lazy(() => import("./Map"));

interface HomeProps {
  handleScrollTop: () => void;
}

const Home: React.FC<HomeProps> = ({ handleScrollTop }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        position: "relative",
        background: "#151515",
        pt: { xs: "15px", sm: "0" },
        minHeight: "100vh",
      }}
    >
      {/* 首屏关键内容不使用 Suspense，直接渲染 */}
      <Banner />
      <Highlights />

      {/* 非首屏内容使用一个统一的 Suspense */}
      <Suspense fallback={<Loading />}>
        <CardList />
        <PartnerCard />
        <Map />
      </Suspense>
    </Box>
  );
};

export default React.memo(Home);
