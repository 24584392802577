import { useAppSelector } from "./index";
import { useMemo } from "react";
import { formatCurrency } from "src/helpers";

export interface BannerData {
  totalStakedValue: string | null;
  marketCapValue: string | null;
  treasuryValue: string | null;
  destoryValue: number;
  isLoading: boolean;
}

export const useBannerData = (): BannerData => {
  const tokenBalInStaking = useAppSelector(state => state.app.tokenBalInStaking);
  const marketPrice = useAppSelector(state => state.app.marketPrice);
  const totalBurn = useAppSelector(state => state.app.totalBurn);
  const marketCap = useAppSelector(state => state.app.marketCap);
  const treasuryMarketValue = useAppSelector(state => state.app.treasuryMarketValue);

  return useMemo(() => {
    const totalStakedValue = Number(tokenBalInStaking) * Number(marketPrice);
    const destoryValue = totalBurn && marketPrice ? Number(totalBurn) * Number(marketPrice) : 0;

    const isLoading = !marketCap || !treasuryMarketValue || !totalStakedValue;

    return {
      totalStakedValue: totalStakedValue ? formatCurrency(totalStakedValue, 2) : null,
      marketCapValue: marketCap ? formatCurrency(marketCap, 2) : null,
      treasuryValue: typeof treasuryMarketValue === "number" ? formatCurrency(treasuryMarketValue, 2) : null,
      destoryValue,
      isLoading,
    };
  }, [tokenBalInStaking, marketPrice, totalBurn, marketCap, treasuryMarketValue]);
};
