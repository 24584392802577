import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import ierc20AbiJson from "../abi/IERC20.json";
import sOHMv2Json from "../abi/sOhmv2.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import ReleasePoolABIJson from "../abi/ReleasePool.json";
import ReleasePoolHelperABIJson from "../abi/ReleasePoolHelper.json";
import OhmDaiContractJson from "../abi/bonds/OhmDaiContract.json";

import { fetchDataFromURL, getTokenDecimals, setAll, trim } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { client } from "src/hooks/wagmi";
import { erc20ABI } from "wagmi";
import { formatUnits } from "viem";

const ierc20Abi = ierc20AbiJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const ReleasePoolABI = ReleasePoolABIJson.abi;
const ReleasePoolHelperABI = ReleasePoolHelperABIJson.abi;
const DaiBondABI = OhmDaiContractJson.abi;
export const getBalances1 = createAsyncThunk(
  "account/getBalances1",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    try {
      console.log("getBalances1 coming in", new Date().toISOString());
      const ohmContract = new ethers.Contract(
        addresses[networkID].OHM_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      const wbnbContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, sOHMv2, provider) as SOhmv2;

      const sohmBalance = await sohmContract.balanceOf(address);
      const wbnbBalance = await wbnbContract.balanceOf(address);
      const daiContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      const stakingContract = new ethers.Contract(
        addresses[networkID].STAKING_ADDRESS as string,
        OlympusStakingABI,
        provider,
      ) as OlympusStakingv2;

      const daiBalance = await daiContract.balanceOf(address);

      const ohmBalance = await ohmContract.balanceOf(address);
      let gonsBal, isLocked;
      const info = await stakingContract.warmupInfo(address);
      gonsBal = await sohmContract.balanceForGons(info.gons);
      const epoch = await stakingContract.epoch();
      isLocked = Number(epoch.number) < Number(info.expiry);
      console.log("[lock]", epoch.number, info.expiry);
      const res = await fetchDataFromURL("maxPrincipal", {}, true);
      console.log("res maxPrincipal", res);
      const maxPrincipal = res.maxPrincipal;
      const principal = await stakingContract.principal(address);

      const contributionPrincipalAmt = Math.min(maxPrincipal, Number(principal));
      const principalAmt = await stakingContract.principal(address);
      const [daiDecimals] = await Promise.all([
        getTokenDecimals(addresses[networkID].USDT_ADDRESS as string, networkID),
      ]);
      const interestBal = sohmBalance.add(gonsBal).gt(principalAmt) ? sohmBalance.add(gonsBal).sub(principalAmt) : "0";
      const avaiablePrincipalAmt = sohmBalance.gt(principalAmt) ? principalAmt : sohmBalance;
      const avaiableInterest = sohmBalance.gt(interestBal) ? interestBal : sohmBalance;
      console.log("getBalances1 coming out", new Date().toISOString());
      return {
        balances: {
          ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
          sOHM: ethers.utils.formatUnits(sohmBalance, "gwei"),
          gonsBal: gonsBal && ethers.utils.formatUnits(gonsBal, "gwei"),
          dai: ethers.utils.formatUnits(daiBalance, daiDecimals),
          principalAmt: ethers.utils.formatUnits(principalAmt, "gwei"),
          contributionPrincipalAmt: ethers.utils.formatUnits(contributionPrincipalAmt, "gwei"),
          maxPrincipal: ethers.utils.formatUnits(maxPrincipal, "gwei"),
          // bTokenBalance: ethers.utils.formatUnits(bTokenBalance, "18"),
          wbnb: ethers.utils.formatUnits(wbnbBalance, "18"),
          interest: ethers.utils.formatUnits(interestBal, "gwei"),
          avaiableInterest: ethers.utils.formatUnits(avaiableInterest, "gwei"),
          avaiablePrincipalAmt: ethers.utils.formatUnits(avaiablePrincipalAmt, "gwei"),
        },
        isLocked,
      };
    } catch (error) {
      console.log("erro", error);
    }
  },
);

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    try {
      console.log("getBalances coming in", new Date().toISOString(), { address, networkID, provider });

      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;

      const [daiBalanceRes, ohmBalanceRes, sohmBalanceRes, wbnbBalanceRes, infoRes, epochRes, principalRes] =
        await client.multicall({
          contracts: [
            {
              address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
              abi: erc20ABI,
              functionName: "balanceOf",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].OHM_ADDRESS as `0x${string}`,
              abi: erc20ABI,
              functionName: "balanceOf",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].SOHM_ADDRESS as `0x${string}`,
              abi: erc20ABI,
              functionName: "balanceOf",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].WBNB_ADDRESS as `0x${string}`,
              abi: erc20ABI,
              functionName: "balanceOf",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].STAKING_ADDRESS as `0x${string}`,
              abi: OlympusStakingABI,
              functionName: "warmupInfo",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].STAKING_ADDRESS as `0x${string}`,
              abi: OlympusStakingABI,
              functionName: "epoch",
              args: [],
            },
            {
              address: addresses[networkID].STAKING_ADDRESS as `0x${string}`,
              abi: OlympusStakingABI,
              functionName: "principal",
              args: [address as `0x${string}`],
            },
          ],
        });
      console.log("getBalances multicalldaiBalanceRes", {
        daiBalanceRes,
        ohmBalanceRes,
        sohmBalanceRes,
        wbnbBalanceRes,
        infoRes,
        epochRes,
        principalRes,
      });
      // const daiBalance = await daiContract.balanceOf(address);

      // const ohmBalance = await ohmContract.balanceOf(address);
      let gonsBal, isLocked;
      // const info = await stakingContract.warmupInfo(address);
      // const epoch = await stakingContract.epoch();
      // isLocked = Number(epoch.number) < Number(info.expiry);
      // const gonsBal = infoRes.result;

      const [daiDecimals, maxPrincipalRes, gonsBalRes] = await Promise.all([
        getTokenDecimals(addresses[networkID].USDT_ADDRESS as string, networkID),
        fetchDataFromURL("maxPrincipal", {}, true),
        client.readContract({
          address: addresses[networkID].SOHM_ADDRESS as string,
          abi: sOHMv2,
          functionName: "balanceForGons",
          args: [infoRes.result[1]],
        }),
        // sohmContract.balanceForGons(infoRes.result[1]), //info.gons
      ]);
      // const [daiDecimals, maxPrincipalRes] = await Promise.all([
      //   getTokenDecimals(addresses[networkID].USDT_ADDRESS as string, networkID),
      //   fetchDataFromURL("maxPrincipal", {}, true),

      // ]);
      // let gonsBalRes = BigInt(0);
      console.log("getBalances coming", { daiDecimals, maxPrincipalRes, gonsBalRes });
      const sohmBalance = sohmBalanceRes.result;
      gonsBal = gonsBalRes;
      isLocked = epochRes.result[1] < infoRes.result[2]; // infoRes.result[2] = info.expiry  epoch.number == epoepochRes.result[1];
      console.log("getBalances [lock]", epochRes.result[1], infoRes.result[2], isLocked);
      const maxPrincipal = maxPrincipalRes.maxPrincipal;
      const principal = principalRes.result;

      const contributionPrincipalAmt = Math.min(maxPrincipal, Number(principal));
      const principalAmt = principal;
      // const interestBal = sohmBalance.add(gonsBal).gt(principalAmt) ? sohmBalance.add(gonsBal).sub(principalAmt) : "0";
      // const avaiablePrincipalAmt = sohmBalance.gt(principalAmt) ? principalAmt : sohmBalance;
      // const avaiableInterest = sohmBalance.gt(interestBal) ? interestBal : sohmBalance;
      console.log("getBalances interestBal", {
        sohmBalance,
        gonsBal,
        gonsBal1: BigInt(gonsBal.toString()),
        principalAmt,
        // inter: sohmBalance + gonsBal - principalAmt,
      });
      const interestBal =
        BigInt(sohmBalance.toString()) + BigInt(gonsBal.toString()) > BigInt(principalAmt.toString())
          ? BigInt(sohmBalance.toString()) + BigInt(gonsBal.toString()) - BigInt(principalAmt.toString())
          : BigInt(0);

      const avaiablePrincipalAmt =
        BigInt(sohmBalance.toString()) > BigInt(principalAmt.toString()) ? principalAmt : sohmBalance;

      const avaiableInterest = BigInt(sohmBalance.toString()) > interestBal ? interestBal : sohmBalance;
      console.log("getBalances coming out", new Date().toISOString());
      return {
        balances: {
          ohm: ethers.utils.formatUnits(ohmBalanceRes.result, "gwei"),
          sOHM: ethers.utils.formatUnits(sohmBalance, "gwei"),
          gonsBal: gonsBal && ethers.utils.formatUnits(gonsBal, "gwei"),
          dai: ethers.utils.formatUnits(daiBalanceRes.result, daiDecimals),
          principalAmt: ethers.utils.formatUnits(principalAmt, "gwei"),
          contributionPrincipalAmt: ethers.utils.formatUnits(contributionPrincipalAmt, "gwei"),
          maxPrincipal: ethers.utils.formatUnits(maxPrincipal, "gwei"),
          // bTokenBalance: ethers.utils.formatUnits(bTokenBalance, "18"),
          wbnb: ethers.utils.formatUnits(wbnbBalanceRes.result, "18"),
          interest: ethers.utils.formatUnits(interestBal, "gwei"),
          avaiableInterest: ethers.utils.formatUnits(avaiableInterest, "gwei"),
          avaiablePrincipalAmt: ethers.utils.formatUnits(avaiablePrincipalAmt, "gwei"),
        },
        isLocked,
      };
    } catch (error) {
      console.log("getBalances error", error);
    }
  },
);

export const loadAccountDetails1 = createAsyncThunk(
  "account/loadAccountDetails1",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    // console.log("approve staking loadAccountDetails start");
    try {
      console.log("loadAccountDetails coming in", new Date().toISOString());

      const ohmContract = new ethers.Contract(
        addresses[networkID].OHM_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
      const bTokenContract = new ethers.Contract(addresses[networkID].bToken as string, ierc20Abi, provider) as IERC20;
      const usdtContract = new ethers.Contract(
        addresses[networkID].USDT_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const wbnbContract = new ethers.Contract(
        addresses[networkID].WBNB_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const releasePoolAllowance = await wbnbContract.allowance(address, addresses[networkID].stakingReleasePool);
      // const releasePoolAllowance = await bTokenContract.allowance(
      //   address,
      //   addresses[networkID].stakingReleasePool
      // );
      const bTokenrewardDistributorAllowance = await wbnbContract.allowance(
        address,
        addresses[networkID].contributionReleasePool,
      );
      // const bTokenrewardDistributorAllowance = await bTokenContract.allowance(
      //   address,
      //   addresses[networkID].contributionReleasePool
      // );
      const rewardDistributorAllowance = await ohmContract.allowance(address, addresses[networkID].rewardDistributor);

      console.log("[getBalances]", {
        rewardDistributorAllowance,
        bTokenrewardDistributorAllowance,
      });
      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;

      const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);
      console.log("loadAccountDetails", {
        stakeAllowance,
        unstakeAllowance,
        releasePoolAllowance,
      });
      const ohmAllowanceInRouter = await ohmContract.allowance(address, addresses[networkID].pancakeRouter);
      const usdtAllowanceInRouter = await usdtContract.allowance(address, addresses[networkID].pancakeRouter);
      const wbnbAllowanceInRouter = await wbnbContract.allowance(address, addresses[networkID].pancakeRouter);
      // const btokenAllowanceInRouter = await bTokenContract.allowance(
      //   address,
      //   addresses[networkID].pancakeRouter
      // );
      // console.log("approve staking loadAccountDetails start get balances");
      dispatch(getBalances({ address, networkID, provider }));
      // console.log("approve staking loadAccountDetails end", {
      //   stakeAllowance,
      //   unstakeAllowance,
      // });
      console.log("loadAccountDetails coming out", new Date().toISOString());

      return {
        staking: {
          ohmStake: Number(ethers.utils.formatUnits(stakeAllowance, 9)),
          ohmUnstake: Number(ethers.utils.formatUnits(unstakeAllowance, 9)),
        },
        releasePoolAllowance: ethers.utils.formatUnits(releasePoolAllowance, "18"),
        rewardDistributorAllowance: ethers.utils.formatUnits(rewardDistributorAllowance, "9"),
        bTokenrewardDistributorAllowance: ethers.utils.formatUnits(bTokenrewardDistributorAllowance, "18"),
        ohmAllowanceInRouter: ethers.utils.formatUnits(ohmAllowanceInRouter, "9"),
        usdtAllowanceInRouter: ethers.utils.formatUnits(usdtAllowanceInRouter, "18"),
        wbnbAllowanceInRouter: ethers.utils.formatUnits(wbnbAllowanceInRouter, "18"),
      };
    } catch (error) {
      console.log("loading error", error);
    }
  },
);

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    try {
      // 使用 multicall 获取多个合约调用的结果
      console.log("loadAccountDetails coming in", new Date().toISOString());

      const [
        stakeAllowanceRes,
        unstakeAllowanceRes,
        releasePoolAllowanceRes,
        bTokenrewardDistributorAllowanceRes,
        rewardDistributorAllowanceRes,
        ohmAllowanceInRouterRes,
        usdtAllowanceInRouterRes,
        wbnbAllowanceInRouterRes,
      ] = await client.multicall({
        contracts: [
          {
            address: addresses[networkID].OHM_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].STAKING_HELPER_ADDRESS as `0x${string}`],
          },
          {
            address: addresses[networkID].SOHM_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].STAKING_ADDRESS as `0x${string}`],
          },
          {
            address: addresses[networkID].WBNB_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].stakingReleasePool as `0x${string}`],
          },
          {
            address: addresses[networkID].WBNB_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].contributionReleasePool as `0x${string}`],
          },
          {
            address: addresses[networkID].OHM_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].rewardDistributor as `0x${string}`],
          },
          {
            address: addresses[networkID].OHM_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].pancakeRouter as `0x${string}`],
          },
          {
            address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].pancakeRouter as `0x${string}`],
          },
          {
            address: addresses[networkID].WBNB_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, addresses[networkID].pancakeRouter as `0x${string}`],
          },
        ],
      });
      // dispatch(getBalances({ address, networkID, provider }));
      console.log("loadAccountDetails coming out", new Date().toISOString());

      return {
        staking: {
          ohmStake: Number(ethers.utils.formatUnits(stakeAllowanceRes.result, 9)),
          ohmUnstake: Number(ethers.utils.formatUnits(unstakeAllowanceRes.result, 9)),
        },
        releasePoolAllowance: ethers.utils.formatUnits(releasePoolAllowanceRes.result, "18"),
        rewardDistributorAllowance: ethers.utils.formatUnits(rewardDistributorAllowanceRes.result, "9"),
        bTokenrewardDistributorAllowance: ethers.utils.formatUnits(bTokenrewardDistributorAllowanceRes.result, "18"),
        ohmAllowanceInRouter: ethers.utils.formatUnits(ohmAllowanceInRouterRes.result, "9"),
        usdtAllowanceInRouter: ethers.utils.formatUnits(usdtAllowanceInRouterRes.result, "18"),
        wbnbAllowanceInRouter: ethers.utils.formatUnits(wbnbAllowanceInRouterRes.result, "18"),
      };
    } catch (error) {
      console.log("loading error", error);
    }
  },
);

export interface IUserBondDetails {
  allowance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: 0,
        balance: "0",
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());
    console.log("[calculateUserBondDetails]", { address, bond });

    // Calculate bond details.
    const bondContract = bond.getContractForBond(networkID, provider);
    console.log("[calculateUserBondDetails]", { address, bond, bondContract });

    const reserveContract = bond.getContractForReserve(networkID, provider);
    let pendingPayout, bondMaturationBlock;
    const daiContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20Abi, provider) as IERC20;
    // let bondDetails = await bondContract.getBondInfoData(address);
    let bondDetails = await client.readContract({
      address: bondContract.address,
      abi: DaiBondABI,
      functionName: "getBondInfoData",
      args: [address],
    });
    console.log("[calculateUserBondDetails]", { bondDetails, bond }, bondDetails.length);
    if (bondDetails.length > 0) {
      if (bond.name === "nvb-usdt_lp") {
        bondDetails = await Promise.all(
          bondDetails.map(async (bondItem: any) => {
            let pendingPayout, bondMaturationBlock, daiBalance, daiAllowance;
            console.log("bondDetails", { bondItem });
            let interestDue: BigNumberish = formatUnits(bondItem.payout, 9);
            console.log("bondDetails 1", { bondItem });
            // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
            bondMaturationBlock = Number(bondItem.vesting) + Number(bondItem.lastBlock);
            console.log("bondDetails 2", { bondMaturationBlock });

            const bondHelperAddress: string = bond.getAddressForBondHelper(networkID) as string;
            console.log("bondDetails 3", { bondMaturationBlock });

            const multicallResults = await client.multicall({
              contracts: [
                {
                  address: bondContract.address as `0x${string}`,
                  abi: DaiBondABI as any,
                  functionName: "pendingPayoutFor",
                  args: [address as `0x${string}`, Number(bondItem.id), false],
                },
                {
                  address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "balanceOf",
                  args: [address as `0x${string}`],
                },
                {
                  address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "allowance",
                  args: [address as `0x${string}`, bondHelperAddress],
                },
              ],
            });
            const [pendingPayoutRes, daiBalanceRes, daiAllowanceRes] = multicallResults;
            pendingPayout = pendingPayoutRes.result;
            daiAllowance = daiAllowanceRes.result;
            daiBalance = daiBalanceRes.result;
            console.log("bondDetails bond daiAllowance", { multicallResults });
            return {
              bond: bond.name,
              displayName: bond.displayName,
              bondIconSvg: bond.bondIconSvg,
              isLP: bond.isLP,
              allowance: [ethers.utils.formatEther(daiAllowance)],
              balance: [ethers.utils.formatEther(daiBalance)],
              interestDue,
              bondMaturationBlock,
              pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
              id: bondItem.id,
            };
          }),
        );
      } else {
        bondDetails = await Promise.all(
          bondDetails.map(async (bondItem: any) => {
            let pendingPayout, bondMaturationBlock;

            let interestDue: BigNumberish = formatUnits(bondItem.payout, 9);
            console.log("bondDetails 1", { bondItem });
            // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
            bondMaturationBlock = Number(bondItem.vesting) + Number(bondItem.lastBlock);
            console.log("bondDetails 2", { bondMaturationBlock });

            // pendingPayout = await bondContract.pendingPayoutFor(address, bondItem.id, false);

            let allowance,
              balance = BigNumber.from(0),
              decimals;

            const multicallResults = await client.multicall({
              contracts: [
                {
                  address: bondContract.address as `0x${string}`,
                  abi: DaiBondABI as any,
                  functionName: "pendingPayoutFor",
                  args: [address as `0x${string}`, bondItem.id, false],
                },
                {
                  address: reserveContract.address as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "balanceOf",
                  args: [address as `0x${string}`],
                },
                {
                  address: reserveContract.address as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "allowance",
                  args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as `0x${string}`],
                },
                {
                  address: reserveContract.address as `0x${string}`,
                  abi: erc20ABI,
                  functionName: "decimals",
                },
              ],
            });
            const [pendingPayoutRes, daiBalanceRes, daiAllowanceRes, decimalsRes] = multicallResults;
            pendingPayout = pendingPayoutRes.result;
            allowance = daiAllowanceRes.result;
            balance = daiBalanceRes.result;
            decimals = decimalsRes.result;
            //  console.log("bond daiAllowance", {

            //  });
            const balanceVal = ethers.utils.formatUnits(balance, decimals);
            const allownanceVal = ethers.utils.formatUnits(allowance, decimals);
            // balanceVal should NOT be converted to a number. it loses decimal precision
            return {
              bond: bond.name,
              displayName: bond.displayName,
              bondIconSvg: bond.bondIconSvg,
              isLP: bond.isLP,
              allowance: [Number(allownanceVal.toString()), Number(allownanceVal.toString())],
              balance: [balanceVal, balanceVal],
              interestDue,
              bondMaturationBlock,
              pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
              id: bondItem.id,
            };
          }),
        );
      }
      console.log(" bondasd bondDetails", bondDetails);

      return bondDetails;
    } else {
      if (bondDetails.length == 0) {
        if (bond.name === "nvb-usdt_lp") {
          const multicallResults = await client.multicall({
            contracts: [
              {
                address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
                abi: erc20ABI,
                functionName: "balanceOf",
                args: [address as `0x${string}`],
              },
              {
                address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
                abi: erc20ABI,
                functionName: "allowance",
                args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as string],
              },
            ],
          });
          const [daiBalanceRes, daiAllowanceRes] = multicallResults;

          const daiBalance = daiBalanceRes.result;
          const daiAllowance = daiAllowanceRes.result;
          return [
            {
              bond: bond.name,
              displayName: bond.displayName,
              bondIconSvg: bond.bondIconSvg,
              isLP: bond.isLP,
              allowance: [ethers.utils.formatEther(daiAllowance)],
              balance: [ethers.utils.formatEther(daiBalance)],
              interestDue: 0,
              bondMaturationBlock: 0,
              pendingPayout: "",
            },
          ];
        } else {
          let allowance,
            balance = BigNumber.from(0),
            decimals = 18;

          const multicallResults = await client.multicall({
            contracts: [
              {
                address: reserveContract.address,
                abi: erc20ABI,
                functionName: "balanceOf",
                args: [address as `0x${string}`],
              },
              {
                address: reserveContract.address,
                abi: erc20ABI,
                functionName: "allowance",
                args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as `0x${string}`],
              },
              {
                address: reserveContract.address,
                abi: erc20ABI,
                functionName: "decimals",
              },
            ],
          });
          const [daiBalanceRes, daiAllowanceRes, decimalsRes] = multicallResults;
          decimals = decimalsRes.result;
          balance = daiBalanceRes.result;
          allowance = daiAllowanceRes.result;
          // formatEthers takes BigNumber => String
          const balanceVal = ethers.utils.formatUnits(balance, decimals);
          allowance = ethers.utils.formatUnits(allowance, decimals);
          return [
            {
              bond: bond.name,
              displayName: bond.displayName,
              bondIconSvg: bond.bondIconSvg,
              isLP: bond.isLP,
              allowance: [Number(allowance.toString())],
              balance: [balanceVal, balanceVal],
              interestDue: 0,
              bondMaturationBlock: 0,
              pendingPayout: "",
            },
          ];
        }
      } else {
        let pendingPayout, bondMaturationBlock;

        // let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
        // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
        // bondMaturationBlock = Number(bondDetails.vesting) + Number(bondDetails.lastBlock);
        let interestDue: BigNumberish = formatUnits(bondDetails.payout, 9);
        console.log("bondDetails 1", { bondDetails });
        // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
        bondMaturationBlock = Number(bondDetails.vesting) + Number(bondDetails.lastBlock);
        console.log("bondDetails 2", { bondMaturationBlock });
        let allowance,
          balance = BigNumber.from(0);
        // pendingPayout = await bondContract.pendingPayoutFor(address);

        // allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
        // balance = await reserveContract.balanceOf(address);
        // [pendingPayout, allowance, balance] = await Promise.all([
        //   bondContract.pendingPayoutFor(address),
        //   reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string),
        //   reserveContract.balanceOf(address),
        // ]);
        const multicallResults = await client.multicall({
          contracts: [
            {
              address: bondContract.address as `0x${string}`,
              abi: DaiBondABI as any,
              functionName: "pendingPayoutFor",
              args: [address as `0x${string}`],
            },
            {
              address: reserveContract.address,
              abi: erc20ABI,
              functionName: "balanceOf",
              args: [address as `0x${string}`],
            },
            {
              address: reserveContract.address,
              abi: erc20ABI,
              functionName: "allowance",
              args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as `0x${string}`],
            },
          ],
        });
        const [pendingPayoutRes, daiBalanceRes, daiAllowanceRes] = multicallResults;
        pendingPayout = pendingPayoutRes.result;
        balance = daiBalanceRes.result;
        allowance = daiAllowanceRes.result;
        // formatEthers takes BigNumber => String
        const balanceVal = ethers.utils.formatEther(balance);
        // balanceVal should NOT be converted to a number. it loses decimal precision
        allowance = ethers.utils.formatUnits(allowance, "18");
        return [
          {
            bond: bond.name,
            displayName: bond.displayName,
            bondIconSvg: bond.bondIconSvg,
            isLP: bond.isLP,
            allowance: [Number(allowance.toString())],
            balance: [balanceVal],
            interestDue,
            bondMaturationBlock,
            pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
          },
        ];
      }
    }
  },
);

export const calculateUserDirectBondDetails1 = createAsyncThunk(
  "account/calculateUserDirectBondDetails1",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // Calculate bond details.
    try {
      const bondContract = bond.getContractForBond(networkID, provider);
      const reserveContract = bond.getContractForReserve(networkID, provider);
      const busdContract = new ethers.Contract(
        addresses[networkID].USDT_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      let bondDetails = await bondContract.inviteBond(address);

      let pendingPayout, bondMaturationBlock;

      let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
      bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
      pendingPayout = await bondContract.pendingPayoutFor(address, "0", true);
      let allowance,
        balance = BigNumber.from(0);
      allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
      balance = await reserveContract.balanceOf(address);
      const busdBalance = await busdContract.balanceOf(address);
      const busdAllowance = await busdContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
      const balanceVal = ethers.utils.formatEther(balance);
      return [
        {
          bond: bond.name,
          displayName: bond.displayName,
          bondIconSvg: bond.bondIconSvg,
          isLP: bond.isLP,
          allowance: [
            // Number(busdAllowance.toString()),
            // Number(allowance.toString()),
            ethers.utils.formatEther(busdAllowance),
            ethers.utils.formatEther(allowance),
          ],
          balance: [ethers.utils.formatEther(busdBalance), balanceVal],
          interestDue,
          bondMaturationBlock,
          pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
        },
      ];
    } catch (error) {
      console.log(error, "calculateUserDirectBondDetails error");
    }
  },
);

export const calculateUserDirectBondDetails = createAsyncThunk(
  "account/calculateUserDirectBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // Calculate bond details.
    try {
      const bondContract = bond.getContractForBond(networkID, provider);
      const reserveContract = bond.getContractForReserve(networkID, provider);
      const busdContract = new ethers.Contract(
        addresses[networkID].USDT_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      console.log("calculateUserDirectBondDetails contracts", {
        bondContract,
        reserveContract,
        bondAddress: bondContract.address,
        reserveAddress: reserveContract.address,
      });

      const multicallResults = await client.multicall({
        contracts: [
          {
            address: bondContract.address as `0x${string}`,
            abi: DaiBondABI as any,
            functionName: "inviteBond",
            args: [address as `0x${string}`],
          },
          {
            address: bondContract.address as `0x${string}`,
            abi: DaiBondABI as any,
            functionName: "pendingPayoutFor",
            args: [address as `0x${string}`, "0", true],
          },
          {
            address: reserveContract.address as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as `0x${string}`],
          },
          {
            address: reserveContract.address as `0x${string}`,
            abi: erc20ABI,
            functionName: "balanceOf",
            args: [address as `0x${string}`],
          },
          {
            address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "balanceOf",
            args: [address as `0x${string}`],
          },
          {
            address: addresses[networkID].USDT_ADDRESS as `0x${string}`,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address as `0x${string}`, bond.getAddressForBondHelper(networkID) as `0x${string}`],
          },
        ],
      });

      console.log("calculateUserDirectBondDetails multicallResults", multicallResults);

      const [bondDetailsRes, pendingPayoutRes, allowanceRes, balanceRes, busdBalanceRes, busdAllowanceRes] =
        multicallResults;

      // if (!bondDetailsRes.result || !pendingPayoutRes.result) {
      //   console.log("Bond contract calls failed", { bondDetailsRes, pendingPayoutRes });
      //   return null;
      // }

      const bondDetails = bondDetailsRes.result;
      const pendingPayout = pendingPayoutRes.result;
      const allowance = allowanceRes.result;
      const balance = balanceRes.result;
      const busdBalance = busdBalanceRes.result;
      const busdAllowance = busdAllowanceRes.result;

      let interestDue: BigNumberish = Number(ethers.utils.formatUnits(bondDetails[2], "gwei")); // [2] payout
      const bondMaturationBlock = Number(bondDetails[3]) + Number(bondDetails[4]); // [3] verting [4] lastBlock
      console.log("calculateUserDirectBondDetails multicallResults", {
        interestDue,
        bondMaturationBlock,
      });

      const balanceVal = ethers.utils.formatEther(balance);
      return [
        {
          bond: bond.name,
          displayName: bond.displayName,
          bondIconSvg: bond.bondIconSvg,
          isLP: bond.isLP,
          allowance: [ethers.utils.formatEther(busdAllowance), ethers.utils.formatEther(allowance)],
          balance: [ethers.utils.formatEther(busdBalance), balanceVal],
          interestDue,
          bondMaturationBlock,
          pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
        },
      ];
    } catch (error) {
      console.error("calculateUserDirectBondDetails error:", error);
      return null;
    }
  },
);

export const getBurnAmount = createAsyncThunk(
  "account/getBurnAmount",
  async ({ provider, address, networkID, amount, isStaking = true }: any, { dispatch }) => {
    const releasePoolContract = new ethers.Contract(
      isStaking
        ? (addresses[networkID].stakingReleasePool as string)
        : (addresses[networkID].contributionReleasePool as string),
      ReleasePoolABI,
      provider,
    ) as any;
    const vestingAmount = ethers.utils.parseUnits(String(amount), "9");
    // const data = await releasePoolContract.levelDetails(6);
    // console.log('data',data)
    let burnAmt1, burnAmt2, burnAmt3, burnAmt4, burnAmt5, burnAmt6;
    if (isStaking) {
      [burnAmt1, burnAmt2, burnAmt3, burnAmt4, burnAmt5] = await Promise.all([
        releasePoolContract.shouldBurnAmount(5, vestingAmount),
        releasePoolContract.shouldBurnAmount(4, vestingAmount),
        releasePoolContract.shouldBurnAmount(3, vestingAmount),
        releasePoolContract.shouldBurnAmount(2, vestingAmount),
        releasePoolContract.shouldBurnAmount(1, vestingAmount),
        // releasePoolContract.shouldBurnAmount(6, vestingAmount),
      ]);
    } else {
      [burnAmt1, burnAmt2, burnAmt3, burnAmt4, burnAmt5, burnAmt6] = await Promise.all([
        releasePoolContract.shouldBurnAmount(5, vestingAmount),
        releasePoolContract.shouldBurnAmount(4, vestingAmount),
        releasePoolContract.shouldBurnAmount(3, vestingAmount),
        releasePoolContract.shouldBurnAmount(2, vestingAmount),
        releasePoolContract.shouldBurnAmount(1, vestingAmount),
        releasePoolContract.shouldBurnAmount(6, vestingAmount),
      ]);
    }
    console.log("[burnAmt15]", {
      vestingAmount,
      burnAmt1,
      burnAmt2,
      burnAmt3,
      burnAmt4,
      burnAmt5,
      burnAmt6,
    });
    if (isStaking) {
      return {
        burn: {
          // amt15: ethers.utils.formatEther(burnAmt6),
          amt30: ethers.utils.formatEther(burnAmt1),
          amt60: ethers.utils.formatEther(burnAmt2),
          amt100: ethers.utils.formatEther(burnAmt3),
          amt150: ethers.utils.formatEther(burnAmt4),
          amt180: ethers.utils.formatEther(burnAmt5),
        },
      };
    } else {
      return {
        teamBurn: {
          amt15: ethers.utils.formatEther(burnAmt6),
          amt30: ethers.utils.formatEther(burnAmt1),
          amt60: ethers.utils.formatEther(burnAmt2),
          amt100: ethers.utils.formatEther(burnAmt3),
          amt150: ethers.utils.formatEther(burnAmt4),
          amt180: ethers.utils.formatEther(burnAmt5),
        },
      };
    }
  },
);

export const getBurnTokenAmount = createAsyncThunk(
  "account/getBurnTokenAmount",
  async ({ provider, address, networkID, amount }: any, { dispatch }) => {
    const releasePoolContract = new ethers.Contract(
      addresses[networkID].contributionReleasePoolV2 as string,
      ReleasePoolABI,
      provider,
    ) as any;
    const vestingAmount = ethers.utils.parseUnits(String(amount), "9");

    const [burnAmt1, burnAmt2, burnAmt3, burnAmt4, burnAmt5, burnAmt6] = await Promise.all([
      releasePoolContract.shouldBurnAmount(5, vestingAmount),
      releasePoolContract.shouldBurnAmount(4, vestingAmount),
      releasePoolContract.shouldBurnAmount(3, vestingAmount),
      releasePoolContract.shouldBurnAmount(2, vestingAmount),
      releasePoolContract.shouldBurnAmount(1, vestingAmount),
      releasePoolContract.shouldBurnAmount(6, vestingAmount),
    ]);
    console.log("[burnAmount]", {
      amount,
      burnAmt1,
      burnAmt2,
      burnAmt3,
      burnAmt4,
      burnAmt5,
    });

    return {
      teamTokenBurn: {
        amt15: ethers.utils.formatUnits(burnAmt6, 9),
        amt30: ethers.utils.formatUnits(burnAmt1, 9),
        amt60: ethers.utils.formatUnits(burnAmt2, 9),
        amt100: ethers.utils.formatUnits(burnAmt3, 9),
        amt150: ethers.utils.formatUnits(burnAmt4, 9),
        amt180: ethers.utils.formatUnits(burnAmt5, 9),
      },
    };
  },
);

export const getLevelUpBurnAmount = createAsyncThunk(
  "account/getLevelUpBurnAmount",
  async ({ provider, address, networkID, amount, isStakeRecord, isBurnToken }: any, { dispatch }) => {
    const releasePoolContract = new ethers.Contract(
      isBurnToken
        ? (addresses[networkID].contributionReleasePoolV2 as string)
        : isStakeRecord
        ? (addresses[networkID].stakingReleasePool as string)
        : (addresses[networkID].contributionReleasePool as string),
      ReleasePoolABI,
      provider,
    ) as any;
    const vestingAmount = ethers.utils.parseUnits(String(trim(amount, 9)), "9");
    let burnAmt1,
      burnAmt2,
      burnAmt3,
      burnAmt4,
      burnAmt5 = "0";
    if (isStakeRecord) {
      [burnAmt1, burnAmt2, burnAmt3, burnAmt4] = await Promise.all([
        releasePoolContract.shouldBurnAmount(5, vestingAmount),
        releasePoolContract.shouldBurnAmount(4, vestingAmount),
        releasePoolContract.shouldBurnAmount(3, vestingAmount),
        releasePoolContract.shouldBurnAmount(2, vestingAmount),
        // releasePoolContract.shouldBurnAmount(6, vestingAmount),
      ]);
    } else {
      [burnAmt1, burnAmt2, burnAmt3, burnAmt4, burnAmt5] = await Promise.all([
        releasePoolContract.shouldBurnAmount(5, vestingAmount),
        releasePoolContract.shouldBurnAmount(4, vestingAmount),
        releasePoolContract.shouldBurnAmount(3, vestingAmount),
        releasePoolContract.shouldBurnAmount(2, vestingAmount),
        releasePoolContract.shouldBurnAmount(6, vestingAmount),
      ]);
    }

    console.log("[level up burnAmount]", {
      amount,
      burnAmt1,
      burnAmt2,
      burnAmt3,
      burnAmt4,
      burnAmt5,
    });
    return {
      levelUP: {
        amt15: isBurnToken ? ethers.utils.formatUnits(burnAmt5, "9") : ethers.utils.formatEther(burnAmt5),
        amt30: isBurnToken ? ethers.utils.formatUnits(burnAmt1, "9") : ethers.utils.formatEther(burnAmt1),
        amt60: isBurnToken ? ethers.utils.formatUnits(burnAmt2, "9") : ethers.utils.formatEther(burnAmt2),
        amt100: isBurnToken ? ethers.utils.formatUnits(burnAmt3, "9") : ethers.utils.formatEther(burnAmt3),
        amt150: isBurnToken ? ethers.utils.formatUnits(burnAmt4, "9") : ethers.utils.formatEther(burnAmt4),
      },
    };
  },
);

interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  directBonds: { [key: string]: IUserBondDetails };
  balances: {
    ohm: string;
    sOHM: string;
    dai: string;
    oldsohm: string;
    fsohm: string;
    wsohm: string;
    wsohmAsSohm: string;
    pool: string;
    busd: string;
    gonsBal: string;
    bTokenBalance: string;
    wbnb: string;
    principalAmt: string;
    avaiablePrincipalAmt: string;
    contributionPrincipalAmt: string;
    maxPrincipal: string;
    interest: string;
    avaiableInterest: string;
  };
  loading: boolean;
  staking: {
    ohmStake: number | null;
    ohmUnstake: number | null;
  };
  releasePoolAllowance: number | null;
  rewardDistributorAllowance: number | null;
  bTokenrewardDistributorAllowance: number | null;
  ohmAllowanceInRouter: number | null;
  usdtAllowanceInRouter: number | null;
  wbnbAllowanceInRouter: number | null;
  burn: {
    amt30: string;
    amt60: string;
    amt100: string;
    amt150: string;
    amt180: string;
  };
  teamBurn: {
    amt15: string;
    amt30: string;
    amt60: string;
    amt100: string;
    amt150: string;
    amt180: string;
  };
  teamTokenBurn: {
    amt15: string;
    amt30: string;
    amt60: string;
    amt100: string;
    amt150: string;
    amt180: string;
  };
  levelUP: {
    amt15: string;
    amt30: string;
    amt60: string;
    amt100: string;
    amt150: string;
  };
  reward: {
    community: number;
    staking: number;
    additionStaking: number;
    total: number;
    mint: number;
  };
  pooling: {
    sohmPool: number;
  };
  isLocked: boolean;
}

const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  directBonds: {},
  balances: {
    ohm: "",
    sOHM: "",
    dai: "",
    oldsohm: "",
    fsohm: "",
    wsohm: "",
    pool: "",
    busd: "",
    wsohmAsSohm: "",
    gonsBal: "",
    principalAmt: "",
    avaiablePrincipalAmt: "",
    contributionPrincipalAmt: "",
    maxPrincipal: "",
    bTokenBalance: "",
    wbnb: "",
    interest: "",
    avaiableInterest: "",
  },
  burn: {
    amt30: "",
    amt60: "",
    amt100: "",
    amt150: "",
    amt180: "",
  },
  teamBurn: {
    amt15: "",
    amt30: "",
    amt60: "",
    amt100: "",
    amt150: "",
    amt180: "",
  },
  teamTokenBurn: {
    amt15: "",
    amt30: "",
    amt60: "",
    amt100: "",
    amt150: "",
    amt180: "",
  },
  levelUP: {
    amt15: "",
    amt30: "",
    amt60: "",
    amt100: "",
    amt150: "",
  },
  releasePoolAllowance: null,
  rewardDistributorAllowance: null,
  bTokenrewardDistributorAllowance: null,
  usdtAllowanceInRouter: null,
  wbnbAllowanceInRouter: null,
  ohmAllowanceInRouter: null,
  staking: { ohmStake: null, ohmUnstake: null },
  reward: { community: 0, staking: 0, additionStaking: 0, mint: 0, total: 0 },
  pooling: { sohmPool: 0 },
  isLocked: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBurnAmount.pending, state => {
        state.loading = true;
      })
      .addCase(getBurnAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBurnAmount.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBurnTokenAmount.pending, state => {
        state.loading = true;
      })
      .addCase(getBurnTokenAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBurnTokenAmount.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getLevelUpBurnAmount.pending, state => {
        state.loading = true;
      })
      .addCase(getLevelUpBurnAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getLevelUpBurnAmount.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        let bond;
        bond = action.payload[0] && action.payload[0].bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log("calculateUserBondDetails", error, state);
      })
      .addCase(calculateUserDirectBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserDirectBondDetails.fulfilled, (state: any, action: any) => {
        if (!action.payload) return;
        let bond;
        bond = action.payload[0].bond;
        state.directBonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserDirectBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
