// 1. 创建自定义 hook 处理 Treasury 相关数据
import { useMemo } from "react";
import { useAppSelector } from "src/hooks";
import { trim, toolNumber } from "src/helpers";

export const useTreasuryData = () => {
  const marketPrice = useAppSelector(state => state.app.marketPrice);
  const totalSupply = useAppSelector(state => state.app.totalSupply);
  const treasuryMarketValue = useAppSelector(state => state.app.treasuryMarketValue);
  const tokenBalInStaking = useAppSelector(state => state.app.tokenBalInStaking);
  const stakingAPY = useAppSelector(state => (state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0));

  return useMemo(
    () => ({
      marketPrice,
      totalSupply,
      treasuryMarketValue,
      stakedBal: Number(trim(Number(tokenBalInStaking), 4)),
      circBal: Number(trim(Number(totalSupply) - Number(tokenBalInStaking), 4)),
      stakingAPY,
      trimmedStakingAPY: trim(stakingAPY * 100, 2),
    }),
    [marketPrice, totalSupply, treasuryMarketValue, tokenBalInStaking, stakingAPY],
  );
};
