import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
} from "recharts";
import { customNumberFormat, formatCurrency, trim } from "src/helpers";
import { useAppSelector, useBonds, useWeb3Context } from "src/hooks";
import { useMobile } from "src/hooks";
const COLORS = ["#EAECEF", "#fcd000"];
const treasuryColors = ["#5AF0BE", "#62A1FF"];
import { t, Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import { useBondData } from '../../hooks/useBondData'
import { useTreasuryData } from '../../hooks/useTreasuryData'
export const MintingChart = () => {
  const { isSmallScreen } = useMobile();
  const { totalSupply, stakedBal, circBal } = useTreasuryData();

  const mintingData = [
    { name: t`Circulating supply`, value: circBal },
    { name: t`Staked`, value: stakedBal },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return isSmallScreen ? null : (
      <text
        x={x > cx ? x + 45 : x - 45}
        y={y}
        fill={COLORS[index]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{
          fontSize: "12px",
        }}
      >
        <tspan x={x > cx ? x + 45 : x - 45} dy="0">
          {name}
        </tspan>
        <tspan x={x > cx ? x + 45 : x - 45} dy="1.2em">{`${customNumberFormat(
          value,
          4
        )} NVB`}</tspan>
      </text>
    );
  };

  const renderCustomLabel = ({ viewBox }: any) => {
    const { cx, cy } = viewBox;
    return (
      <foreignObject x={cx - 50} y={cy - 20} width="100" height="40">
        <Typography
          style={{
            textAlign: "center",
            color: "#EAECEF",
            fontSize: "14px",
            wordBreak: "break-word",
          }}
        >
          <Trans>Total Minting Amount</Trans>
        </Typography>
      </foreignObject>
    );
  };
  return (
    <PieChart width={isSmallScreen ? 200 : 450} height={200}>
      <Pie
        data={mintingData}
        cx="50%"
        cy="50%"
        innerRadius={isSmallScreen ? 70 : 60}
        outerRadius={isSmallScreen ? 80 : 70}
        // fill="#8884d8"
        paddingAngle={3}
        dataKey="value"
        label={renderCustomizedLabel}
        strokeOpacity="0"
      >
        {mintingData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}

        <Label content={renderCustomLabel} position="center" />
      </Pie>
    </PieChart>
  );
};

export const TreasuryChart = () => {
  const { isSmallScreen } = useMobile();

  const { chainID } = useWeb3Context();
  const { lpBondPurchased, usdtBondPurchased } = useBondData(chainID);

  const treasuryData = [
    { name: t`Bond LP`, value: Number(trim(lpBondPurchased, 2)) },
    { name: t`Bond USDT`, value: Number(trim(usdtBondPurchased, 2)) },
  ];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return isSmallScreen ? null : (
      <text
        x={x > cx ? x + 20 : x - 20}
        y={y}
        fill={treasuryColors[index]}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{
          fontSize: "12px",
        }}
      >
        <tspan x={x > cx ? x + 20 : x - 20} dy="0">
          {name}
        </tspan>
        <tspan x={x > cx ? x + 20 : x - 20} dy="1.2em">{`${formatCurrency(
          value,
          2
        )}`}</tspan>
      </text>
    );
  };

  return (
    <PieChart width={isSmallScreen ? 200 : 450} height={200}>
      <Pie
        data={treasuryData}
        cx="50%"
        cy="50%"
        innerRadius={isSmallScreen ? 70 : 60}
        outerRadius={isSmallScreen ? 80 : 70}
        // fill="#8884d8"
        paddingAngle={3}
        dataKey="value"
        label={renderCustomizedLabel}
        strokeOpacity="0"
      >
        {treasuryData.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={treasuryColors[index % treasuryColors.length]}
          />
        ))}
        <Label
          value={t`Treasury`}
          position="center"
          fill="#EAECEF"
          style={{ fontSize: "14px" }}
        />
      </Pie>
    </PieChart>
  );
};
