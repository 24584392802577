import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Zoom,
  Tabs,
  Tab,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { BondDataCard, BondTableData } from "./BondRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatCurrency } from "../../helpers";
import useBonds from "../../hooks/Bonds";
import { useWeb3Context } from "src/hooks/web3Context";
import { useEffect, useState, useMemo, useCallback } from "react";
import "./choosebond.scss";
import { Skeleton } from "@mui/material";
import { ClaimBonds, ClaimDirectBonds } from "./ClaimBonds";
import { allBondsMap } from "src/helpers/AllBonds";
import { useAppSelector } from "src/hooks";
import BondTitle from "src/assets/images/bond-title.png";
import {
  calculateUserBondDetails,
  calculateUserDirectBondDetails,
  IUserBondDetails,
} from "src/slices/AccountSlice";
import { useMobile } from "../../hooks";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import NoData from "src/assets/images/noData.svg";
import { ClaimBondTableData, ClaimBondCardData } from "./ClaimRow";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import FooterBg from "../../assets/images/footer-bg.png";

import {
  txnButtonText,
  isPendingTxn,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { useDispatch } from "react-redux";

const useBondData = (chainID: number, address: string, provider: any, connected: boolean, tab: number) => {
  const dispatch = useDispatch();
  const { bonds } = useBonds(chainID);

  useEffect(() => {
    let isActive = true;

    if (tab !== 0 && connected && address) {
      // 仅执行一次批量请求
      const fetchData = async () => {
        if (!isActive) return;

        for (const bond of bonds) {
          dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
          dispatch(calculateUserDirectBondDetails({ address, bond, provider, networkID: chainID }));
        }
      };

      fetchData();
    }

    return () => {
      isActive = false;
    };
  }, [tab, connected, address, chainID]); // 移除 provider, dispatch, bonds 依赖

  return { bonds };
};

const useAccountBondsData = () => {
  const accountBonds = useAppSelector((state) => {
    const withInterestDue: any[] = [];
    Object.values(state.account.bonds).forEach((bondData) => {
      if (Array.isArray(bondData)) {
        const validBonds = bondData.filter(
          (bond) => typeof bond.id !== 'undefined' && Number(bond.id) >= 0 && Number(bond.interestDue) > 0
        );
        withInterestDue.push(...validBonds);
      } else if (Number((bondData as IUserBondDetails).interestDue) > 0) {
        withInterestDue.push(bondData);
      }
    });
    return withInterestDue.sort((a, b) => b.pendingPayout - a.pendingPayout);
  });

  return accountBonds;
};

const useDirectBondsData = () => {
  const accountDirectBonds = useAppSelector((state) => {
    const withInterestDue: any[] = [];
    Object.values(state.account.directBonds).forEach((bondData) => {
      if (Array.isArray(bondData)) {
        const validBonds = bondData.filter(
          (bond) => typeof bond.interestDue !== 'undefined' && Number(bond.interestDue) > 0
        );
        withInterestDue.push(...validBonds);
      } else if ((bondData as IUserBondDetails).interestDue > 0) {
        withInterestDue.push(bondData);
      }
    });
    return withInterestDue.sort((a, b) => b.pendingPayout - a.pendingPayout);
  });

  return accountDirectBonds;
};

const useTreasuryData = () => {
  const marketPrice = useAppSelector((state) => state.app.marketPrice);
  const treasuryBalance = useAppSelector((state) => {
    let tokenBalances = 0;
    Object.entries(allBondsMap).forEach(([bond, value]) => {
      if (state.bonding[bond] && state.bonding[bond].bond !== 'nvb-usdt_lp1') {
        tokenBalances += state.bonding[bond].purchased;
      }
    });
    return tokenBalances;
  });

  return { marketPrice, treasuryBalance };
};

function ChooseBond() {
  const dispatch = useDispatch();
  const { provider, address, chainID, connected } = useWeb3Context();

  const { isSmallScreen } = useMobile();
  const [tab, setTab] = useState(0);
  const { bonds } = useBondData(chainID, address, provider, connected, tab);
  const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDirectBondPage, setCurrentDirectBondPage] = useState(1);
  const isAppLoading: boolean = useAppSelector((state) => state.app.loading);
  const isAccountLoading: boolean = useAppSelector(
    (state) => state.account.loading
  );
  const accountBonds = useAccountBondsData();
  const accountDirectBonds = useDirectBondsData();

  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const { marketPrice, treasuryBalance } = useTreasuryData();

  const paginatedData = useMemo(() => {
    const currentPageBonds = accountBonds.slice((currentPage - 1) * 5, currentPage * 5);
    const pageCount = Math.ceil(accountBonds.length / 5);
    const currentPageDirectBonds = accountDirectBonds.slice(
      (currentDirectBondPage - 1) * 5,
      currentDirectBondPage * 5
    );
    const directBondsPageCount = Math.ceil(accountDirectBonds.length / 5);

    return {
      currentPageBonds,
      pageCount,
      currentPageDirectBonds,
      directBondsPageCount
    };
  }, [accountBonds, accountDirectBonds, currentPage, currentDirectBondPage]);

  const handlePageChange = useCallback((event: any, page: number) => {
    setCurrentPage(page);
  }, []);

  const handleDirectBondPageChange = useCallback((event: any, page: number) => {
    setCurrentDirectBondPage(page);
  }, []);

  const handleTabChange = useCallback((_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  }, []);


  const currentPageBonds = paginatedData.currentPageBonds;
  const pageCount = paginatedData.pageCount;
  const currentPageDirectBonds = paginatedData.currentPageDirectBonds;
  const directBondsPageCount = paginatedData.directBondsPageCount;
  console.log("treast", { treasuryBalance, marketPrice });
  return (
    <Box
      display="flex"
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        mb: "100px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          width: { xs: "100%", sm: "97%" },
          pt: { sm: "87px", xs: "18px" },
          px: { xs: "15px", sm: "0" },
          position: "relative",
          zIndex: "1",
        }}
      >
        {/* bond top */}
        <Box
          sx={{
            mb: {
              xs: "20px",
              sm: "50px",
            },
          }}
          display="flex"
          alignItems={"cneter"}
        >
          <Box
            display="flex"
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Box
              sx={{
                mr: { sm: "20px" },
              }}
            >
              <StarIcon />
            </Box>
            <Box display="flex" alignItems={"center"}>
              <BondIcon />
              {/* <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "35.192px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                <Trans>Bond</Trans>
              </Typography> */}
            </Box>
          </Box>
          {/* mobile tile */}
          <Box
            sx={{
              width: "100%",
              "&>img": {
                width: "30%",
              },
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <img src={BondTitle} alt="bonds" />
          </Box>
        </Box>
        {/* bond tabs */}
        <Box
          sx={{
            mb: { sm: "50px", xs: "15px" },
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{
              borderBottom: "1px solid #2D2D2D",
              "& .MuiTabs-indicator": {
                background: "#fff",
              },
            }}
          >
            <Tab
              label={t({
                id: "do_bond",
              })}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
            <Tab
              label={t`Your Bond`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
            <Tab
              label={t`Direct Bond Award`}
              sx={{
                color: "#848E9C",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "capitalize",
                "&.Mui-selected": {
                  color: "#fff",
                  fontWeight: "600",
                },
              }}
            />
          </Tabs>
        </Box>
        {/* tab content */}
        <Box
          sx={{
            borderRadius: { xs: "6px", sm: "10px" },
            border: "1px solid #2d2d2d",
            background: "#151515",
            minHeight: "400px",
            pb: "20px",
          }}
        >
          {tab == 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
              }}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid
                container
                item
                xs={12}
                sx={{ my: { sm: "60x", xs: "30px" } }}
                className="bond-hero"
              >
                <Grid item xs={12} sm={6}>
                  <Box
                    textAlign={`${isVerySmallScreen ? "left" : "center"}`}
                    sx={{
                      mb: isSmallScreen ? 2 : 0,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontSize: { xs: "14px", sm: "20px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>Treasury Balance</Trans>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          color: "#eaecef",
                          fontSize: { xs: "20px", sm: "28px" },
                          fontWeight: "600",
                        }}
                        data-testid="treasury-balance"
                      >
                        {!treasuryBalance ? (
                          <Skeleton
                            width="180px"
                            data-testid="treasury-balance-loading"
                          />
                        ) : treasuryBalance == 0 ? (
                          "--"
                        ) : (
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          }).format(Number(treasuryBalance))
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} className={`ohm-price`}>
                  <Box textAlign={`${isVerySmallScreen ? "right" : "center"}`}>
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontSize: { xs: "14px", sm: "20px" },
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                      align="center"
                    >
                      <Trans>OHM Price</Trans>
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{
                          color: "#EAECEF",
                          fontSize: { xs: "20px", sm: "28px" },
                          fontWeight: "600",
                        }}
                      >
                        {!marketPrice ? (
                          <Skeleton width="100px" />
                        ) : marketPrice == 0 ? (
                          "--"
                        ) : (
                          formatCurrency(Number(marketPrice), 4)
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {!isSmallScreen ? (
                <Grid container item>
                  <TableContainer>
                    <Table aria-label="Available bonds">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderBottom: "none",
                            }}
                          />
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Bond</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Price</Trans>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>ROI</Trans>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              color: "#848E9C",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              borderBottom: "none",
                            }}
                          >
                            <Trans>Bought</Trans>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderBottom: "none" }}
                          ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bonds.map((bond) => (
                          <BondTableData key={bond.name} bond={bond} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    px: { xs: "15px", sm: "0" },
                  }}
                >
                  <Grid container item spacing={2}>
                    {bonds.map((bond, index) => (
                      <Grid item xs={12} key={bond.name}>
                        <BondDataCard key={bond.name} bond={bond} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          ) : tab == 1 ? (
            <Box
              sx={{
                px: { xs: "15px", sm: "20px" },
                height: "100%",
              }}
            >
              <ClaimBonds
                count={pageCount}
                activeBonds={currentPageBonds}
                changePage={handlePageChange}
              />
              {/* {isSmallScreen ? (
                Object.entries(currentPageBonds).length > 0 ? (
                  <Box>

                 { Object.entries(currentPageBonds).map((bond, i) => (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <ClaimBondCardData key={i} userBond={bond} />
                    </Box>

                  ))}

                  </Box>

                ) : (
                  <Box
                    sx={{ width: "100%", height: "330px" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                )
              ) : (
                <ClaimBonds
                  count={pageCount}
                  activeBonds={currentPageBonds}
                  changePage={handlePageChange}
                />
              )} */}
            </Box>
          ) : (
            <Box
              sx={{
                px: { xs: "15px", sm: "20px" },
                height: "100%",
                minHeight: "350px",
              }}
            >
              {/* {isSmallScreen ? (
                Object.entries(currentDirectBondPage).length > 0 ? (
                  <Box>
                    {Object.entries(currentDirectBondPage).map((bond, i) => (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <ClaimBondCardData key={i} userBond={bond} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{ width: "100%", height: "274px" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                )
              ) : ( */}
              <ClaimDirectBonds
                directBondChangePage={handleDirectBondPageChange}
                directBondCount={currentDirectBondPage}
                directBonds={accountDirectBonds}
              />
              {/* )} */}
            </Box>
          )}
        </Box>
      </Box>
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
    </Box>
  );
}

export default ChooseBond;
