import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses, OHM_INITIAL_PRICE } from "../constants";
import CommunityAbiJson from "../abi/Community.json";
import ViewAbiJson from "../abi/View.json";
import RewardDistributor from "../abi/RewardDistributor.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { bigNumber, fetchDataFromURL, setAll } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20ABIJson from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import {
  getBalances,
  loadAccountDetails,
  fetchAccountSuccess,
  getBurnAmount,
  getBurnTokenAmount,
} from "./AccountSlice";
import apollo from "../lib/apolloClient";
import sOHMv2Json from "../abi/sOhmv2.json";
import { INVITE_PER_PAGE } from "src/constants";
import { fetchQueryStakingStatus } from "../helpers";
import { t } from "@lingui/macro";
import dayjs from "dayjs";
import { NetworkID } from "../lib/Bond";
import { client } from "src/hooks/wagmi";
const sOHMv2 = sOHMv2Json.abi;
const CommunityAbi = CommunityAbiJson.abi;
const RewardDistributorAbi = RewardDistributor.abi;
const ViewAbi = ViewAbiJson.abi;

const ierc20ABI = ierc20ABIJson.abi;

export const communityJoin = createAsyncThunk(
  "community/communityJoin",
  async ({ provider, networkID, referer }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      signer,
    );
    let tx;
    try {
      const estimateGas = await communityContract.estimateGas.join(referer);
      tx = await communityContract.join(referer, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const text = "Join";
      const pendingTxnType = "community_join";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        await tx.wait();
        return;
      }
    } catch (e) {
      console.log("[join error", e);
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else if ((e as any).reason.indexOf("Referrer is not member") > -1) {
        console.log("reason", (e as any).reason);
        console.log("message", (e as any).message);
        console.log("data", (e as any).data);
        // dispatch(error((e as any).message));
        dispatch(error(t`Your recommender has not joined the community yet！`));
      } else {
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

export const setTeamName = createAsyncThunk(
  "community/setTeamName",
  async ({ provider, networkID, name }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;

    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      signer,
    );
    let tx;
    try {
      const estimateGas = await communityContract.estimateGas.setTeamName(name);
      tx = await communityContract.setTeamName(name, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const text = "setTeamName";
      const pendingTxnType = "set_team_name";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        await tx.wait();
        return;
      }
    } catch (e) {
      console.log("[join error", e);
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

export const getCommunityData = createAsyncThunk(
  "community/getCommunityData",
  async ({ page, networkID, address }: any, { dispatch }: any) => {
    // const communityContract = new ethers.Contract(
    //   addresses[networkID].COMMUNITY_ADDRESS as string,
    //   CommunityAbi,
    //   provider,
    // );
    // console.log("getCommunityData 1", address);
    try {
      // const communityStatusData = await fetchDataFromURL("communityStatus", {
      //   member: address,
      // });
      // console.log("[communityStatusData]", communityStatusData);
      // const membersData = await communityContract.members(address);
      // let referrals = await communityContract.referrals(address, 0, 0);
      // const [communityStatusData, membersData, referrals] = await Promise.all([
      //   fetchDataFromURL("communityStatus", {
      //     member: address,
      //   }),
      //   communityContract.members(address),
      //   communityContract.referrals(address, 0, 0),
      // ]);
      const [communityStatusData, teamStakingRes, inviteDataRes, multicallData] = await Promise.all([
        fetchDataFromURL("communityStatus", {
          member: address,
        }),
        fetchDataFromURL("teamStaking", {
          member: address,
        }),
        fetchDataFromURL(
          "referrals",
          {
            member: address,
            level: 1,
            limit: INVITE_PER_PAGE,
            offset: (page - 1) * INVITE_PER_PAGE,
          },
          true,
        ),
        client.multicall({
          contracts: [
            {
              address: addresses[networkID].COMMUNITY_ADDRESS as `0x${string}`,
              abi: CommunityAbi,
              functionName: "members",
              args: [address as `0x${string}`],
            },
            {
              address: addresses[networkID].COMMUNITY_ADDRESS as `0x${string}`,
              abi: CommunityAbi,
              functionName: "referrals",
              args: [address as `0x${string}`, 0, 0],
            },
          ],
        }),
      ]);
      const membersData = multicallData[0].result;
      const referrals = multicallData[1].result;
      console.log("getCommunityData 2", {
        multicallData,
        referrals,
        length: referrals[0],
      });
      const dataList = inviteDataRes.list.map((item: any) => ({
        ...item,
        teamStaking: ethers.utils.formatUnits(item.teamStaking, "9"),
      }));
      return {
        isInvited: membersData[1] !== "0x0000000000000000000000000000000000000000",
        referrer: membersData[1],
        directReferralQuantity: Number(referrals[0]),
        teamNumber: communityStatusData.teamQty,
        teamStaking: ethers.utils.formatUnits(teamStakingRes.teamStaking, 9) ?? 0,
        referralsList: dataList,
      };
    } catch (error) {
      console.log("getCommunityData error", error);
      return {
        isInvited: false,
        referrer: "",
      };
    }
  },
);

export const getContributionData = createAsyncThunk(
  "community/getContributionData",
  async ({ provider, networkID, address }: any, { dispatch }: any) => {
    // const []
    const rewardDistributorContract = new ethers.Contract(
      addresses[networkID].rewardDistributor as string,
      RewardDistributorAbi,
      provider,
    );
    // const [
    //   teamStakingRes,
    //   teamContributionRes,
    //   // historyRes
    // ] = await Promise.all([
    //   fetchDataFromURL("teamStaking", {
    //     member: address,
    //   }),
    //   fetchDataFromURL("teamContribution", {
    //     member: address,
    //   }),
    //   // fetchDataFromURL(
    //   //   "rewardHistory",
    //   //   {
    //   //     member: address,
    //   //     pageSize: INVITE_PER_PAGE,
    //   //     pageNumber: 1,
    //   //   },
    //   //   true
    //   // ),
    // ]);

    const [teamRewardRes, totalRewardRes, burnAmtRes, teamStakingRes, teamContributionRes] = await Promise.all([
      rewardDistributorContract.claimable(address),
      rewardDistributorContract.totalReward(address),
      rewardDistributorContract.burntAmtOf(address),
      fetchDataFromURL("teamStaking", {
        member: address,
      }),
      fetchDataFromURL("teamContribution", {
        member: address,
      }),
    ]);
    console.log(
      "[getContributionData]",
      {
        teamStakingRes,
        teamContributionRes,
        // historyRes,
      },
      teamStakingRes.teamStaking ?? 0,
      teamContributionRes.teamContribution ?? 0,
    );
    console.log("rewardDistributorContract", {
      teamRewardRes,
      totalRewardRes,
      burnAmtRes,
    });
    const totalClaimableReward = teamRewardRes[0].add(teamRewardRes[1]).add(teamRewardRes[2]).add(teamRewardRes[3]);
    // const burnAmt = re
    if (Number(ethers.utils.formatUnits(totalClaimableReward, "9")) > 0) {
      dispatch(
        getBurnAmount({
          provider,
          networkID,
          amount: ethers.utils.formatUnits(totalClaimableReward, "9"),
          isStaking: false,
        }),
      );
      dispatch(
        getBurnTokenAmount({
          provider,
          networkID,
          amount: ethers.utils.formatUnits(totalClaimableReward, "9"),
          isStaking: false,
        }),
      );
    }
    // const rewardHistoryList = historyRes.list.map((item: any) => ({
    //   ...item,
    //   amount: ethers.utils.formatUnits(item.amount, "9"),
    // }));
    return {
      teamStaking: ethers.utils.formatUnits(teamStakingRes.teamStaking, 9) ?? 0,
      teamContribution: teamContributionRes.teamContribution ?? 0,
      totalClaimableReward: ethers.utils.formatUnits(totalClaimableReward, "9"),
      totalContribution: ethers.utils.formatUnits(totalRewardRes.totalContribution, "9"),
      totalBondReward: ethers.utils.formatUnits(totalRewardRes.totalBondReward, "9"),
      totalNewContribution: ethers.utils.formatUnits(totalRewardRes.totalNewContribution, "9"),
      totalTopContribution: ethers.utils.formatUnits(totalRewardRes.totalTopContribution, "9"),
      burnAmt: ethers.utils.formatUnits(burnAmtRes, "9"),
      // rewardHistoryList: rewardHistoryList,
      // rewardHistoryTotal: historyRes.total,
    };
  },
);

export const getFlowHistory = createAsyncThunk(
  "community/getFlowHistory",
  async ({ provider, networkID, address, page }: any, { dispatch }: any) => {
    const rewardHistoryRes = await fetchDataFromURL(
      "rewardHistory",
      {
        member: address,
        pageSize: INVITE_PER_PAGE,
        pageNumber: page,
      },
      true,
    );
    console.log("aaddress getFlowHistory", { rewardHistoryRes });
    const rewardHistoryList = rewardHistoryRes.list.map((item: any) => ({
      ...item,
      amount: ethers.utils.formatUnits(item.amount, "9"),
    }));
    return {
      rewardHistoryList: rewardHistoryList,
      rewardHistoryTotal: rewardHistoryRes.total,
    };
  },
);

export const getInviteData = createAsyncThunk(
  "community/getInviteData",
  async ({ provider, networkID, address, page }: any, { dispatch }: any) => {
    try {
      const resData = await fetchDataFromURL(
        "referrals",
        {
          member: address,
          level: 1,
          limit: INVITE_PER_PAGE,
          offset: (page - 1) * INVITE_PER_PAGE,
        },
        true,
      );
      console.log("invite list ", resData);

      const dataList = resData.list.map((item: any) => ({
        ...item,
        teamStaking: ethers.utils.formatUnits(item.teamStaking, "9"),
      }));
      console.log("invite list ", dataList);
      return {
        referralsList: dataList,
      };
    } catch (error) {
      console.log("getCommunityData error", error);
      return {
        referralsList: [],
      };
    }
  },
);

export const getInviteDataTemp = createAsyncThunk(
  "community/getInviteDataTemp",
  async ({ provider, networkID, address, page }: any, { dispatch }: any) => {
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      provider,
    );
    // console.log("getCommunityData 1", address, page);
    try {
      let referrals = await communityContract.referrals(address, 0, 0);

      if (referrals._length >= INVITE_PER_PAGE * page) {
        referrals = await communityContract.referrals(address, INVITE_PER_PAGE * (page - 1), INVITE_PER_PAGE);
      } else {
        referrals = await communityContract.referrals(
          address,
          INVITE_PER_PAGE * (page - 1),
          referrals._length - INVITE_PER_PAGE * (page - 1),
        );
      }
      console.log("getCommunityData 4", referrals);

      return {
        referralsList: referrals.referrals_,
      };
    } catch (error) {
      console.log("getCommunityData error", error);
      return {
        referralsList: [],
      };
    }
  },
);

export const getRankingList = createAsyncThunk(
  "community/getRankingList",
  async ({ page, networkID, provider }: any, { dispatch }: any) => {
    const rankingRes = await fetchDataFromURL(
      "ranking",
      {
        limit: 99,
        offset: (page - 1) * 99,
      },
      true,
    );
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      provider,
    );
    const viewContract = new ethers.Contract(addresses[networkID].View as string, ViewAbi, provider);
    let rankingList = rankingRes.list;

    const addrList = rankingList.map((item: any) => item.member);
    const list = await viewContract.membersNames(addresses[networkID].COMMUNITY_ADDRESS, addrList);
    const rankingListData = rankingList.map((item: any, i: number) => ({
      ...item,
      teamName: list[i],
    }));
    console.log("rankinglist ", {
      rankingList,
      addrList,
      list,
      rankingListData,
      rankingRes,
    });
    // const promises = rankingList.map(async (item: any) => {
    //   const membersRes = await communityContract.members(item.member);
    //   return { ...item, teamName: membersRes.teamName };
    // });

    // const promisesRes = await Promise.all(promises);

    // console.log("[getRankingList]", {
    //   rankingRes,
    //   names: promisesRes,
    // });
    return {
      rankingTotal: rankingRes.total,
      rankingList: rankingListData,
      minPower: rankingRes.minPower,
    };
  },
);

interface ICommunitySlice {
  referralsList?: any[];
  isInvited?: boolean;
  referrer?: string;
  directReferralQuantity?: number;
  teamNumber?: string;
  stakeMinGons?: string;
  stakeMinAmount?: string;
  teamStaking?: string;
  teamContribution?: string;
  totalClaimableReward?: string;
  totalContribution?: string;
  totalBondReward?: string;
  totalNewContribution?: string;
  totalTopContribution?: string;
  burnAmt?: string;
  rankingTotal?: number;
  rankingList?: any[];
  minPower?: number;
  rewardHistoryTotal?: number;
  rewardHistoryList?: any[];
}

const initialState: ICommunitySlice = {};

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    fetchCommunitySliceSuccess(state: any, action: { payload: any }) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getCommunityData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getCommunityData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getCommunityData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getFlowHistory.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getFlowHistory.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getFlowHistory.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getContributionData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getContributionData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getContributionData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getRankingList.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getRankingList.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getRankingList.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getInviteDataTemp.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getInviteDataTemp.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getInviteDataTemp.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getInviteData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getInviteData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getInviteData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default communitySlice.reducer;

export const { fetchCommunitySliceSuccess } = communitySlice.actions;

const baseInfo = (state: any) => state.communitySlice;

export const getCommunitySliceState = createSelector(baseInfo, (community: any) => community);
